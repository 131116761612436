import { Trans } from "@lingui/macro";
import { CurrencyAmount, Token } from "@panaromafinance/panaromaswap_sdkcore";
import JSBI from "jsbi";
import styled from "styled-components/macro";

import { BIG_INT_SECONDS_IN_WEEK } from "../../constants/misc";
import { useColor } from "../../hooks/useColor";
import useStablecoinPrice from "../../hooks/useStablecoinPrice";
import { useTotalSupply } from "../../hooks/useTotalSupply";
import { useV1Pair } from "../../hooks/useV1Pairs";
import { StakingInfo } from "../../state/stake/hooks";
import { StyledInternalLink, ThemedText } from "../../theme";
import { currencyId } from "../../utils/currencyId";
import { unwrappedToken } from "../../utils/unwrappedToken";
import { ButtonPrimary } from "../Button";
import { AutoColumn } from "../Column";
import DoubleCurrencyLogo from "../DoubleLogo";
import { RowBetween } from "../Row";
import { Break, CardBGImage, CardNoise } from "./styled";

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 1rem;
  margin-right: 1rem;
  margin-left: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
  display: none;
`};
`;

const Wrapper = styled(AutoColumn)<{ showBackground: boolean; bgColor: any }>`
  border-radius: 12px;
  width: 100%;
  overflow: hidden;
  position: relative;
  opacity: ${({ showBackground }) => (showBackground ? "1" : "1")};
  background: ${({ theme, bgColor, showBackground }) =>
    `radial-gradient(91.85% 100% at 1.84% 0%, ${bgColor} 0%, ${
      showBackground ? theme.deprecated_black : theme.deprecated_bg5
    } 100%) `};
  color: ${({ theme, showBackground }) =>
    showBackground
      ? theme.deprecated_white
      : theme.deprecated_text1} !important;

  ${({ showBackground }) =>
    showBackground &&
    `  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);`}
`;

const TopSection = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 120px;
  grid-gap: 0px;
  align-items: center;
  padding: 1rem;
  z-index: 1;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    grid-template-columns: 48px 1fr 96px;
  `};
`;

const BottomSection = styled.div<{ showBackground: boolean }>`
  padding: 12px 16px;
  opacity: ${({ showBackground }) => (showBackground ? "1" : "0.4")};
  border-radius: 0 0 12px 12px;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  z-index: 1;
`;

export default function PoolCard({
  stakingInfo
}: {
  stakingInfo: StakingInfo;
}) {
  const token0 = stakingInfo.tokens[0];
  const token1 = stakingInfo.tokens[1];

  const currency0 = unwrappedToken(token0);
  const currency1 = unwrappedToken(token1);

  const isStaking = Boolean(stakingInfo.stakedAmount.greaterThan("0"));

  // get the color of the token
  const token = currency0.isNative ? token1 : token0;
  const WETH = currency0.isNative ? token0 : token1;
  const backgroundColor = useColor(token);

  const totalSupplyOfStakingToken = useTotalSupply(
    stakingInfo.stakedAmount.currency
  );
  const [, stakingTokenPair] = useV1Pair(...stakingInfo.tokens);

  // let returnOverMonth: Percent = new Percent('0')
  let valueOfTotalStakedAmountInWETH: CurrencyAmount<Token> | undefined;
  if (totalSupplyOfStakingToken && stakingTokenPair) {
    // take the total amount of LP tokens staked, multiply by ETH value of all LP tokens, divide by all LP tokens
    valueOfTotalStakedAmountInWETH = CurrencyAmount.fromRawAmount(
      WETH,
      JSBI.divide(
        JSBI.multiply(
          JSBI.multiply(
            stakingInfo.totalStakedAmount.quotient,
            stakingTokenPair.reserveOf(WETH).quotient
          ),
          JSBI.BigInt(2) // this is b/c the value of LP shares are ~double the value of the WETH they entitle owner to
        ),
        totalSupplyOfStakingToken.quotient
      )
    );
  }

  // get the USD value of staked WETH
  const USDPrice = useStablecoinPrice(WETH);
  const valueOfTotalStakedAmountInUSDC =
    valueOfTotalStakedAmountInWETH &&
    USDPrice?.quote(valueOfTotalStakedAmountInWETH);

  return (
    <Wrapper showBackground={isStaking} bgColor={backgroundColor}>
      <CardBGImage desaturate />
      <CardNoise />

      <TopSection>
        <DoubleCurrencyLogo
          currency0={currency0}
          currency1={currency1}
          size={24}
        />
        <ThemedText.DeprecatedWhite
          fontWeight={600}
          fontSize={24}
          style={{ marginLeft: "8px" }}>
          {currency0.symbol}-{currency1.symbol}
        </ThemedText.DeprecatedWhite>

        <StyledInternalLink
          to={`/pana/${currencyId(currency0)}/${currencyId(currency1)}`}
          style={{ width: "100%" }}>
          <ButtonPrimary padding="8px" $borderRadius="8px">
            {isStaking ? <Trans>Manage</Trans> : <Trans>Deposit</Trans>}
          </ButtonPrimary>
        </StyledInternalLink>
      </TopSection>

      <StatContainer>
        <RowBetween>
          <ThemedText.DeprecatedWhite>
            <Trans>Total deposited</Trans>
          </ThemedText.DeprecatedWhite>
          <ThemedText.DeprecatedWhite>
            {valueOfTotalStakedAmountInUSDC ? (
              <Trans>
                $
                {valueOfTotalStakedAmountInUSDC.toFixed(0, {
                  groupSeparator: ","
                })}
              </Trans>
            ) : (
              <Trans>
                {valueOfTotalStakedAmountInWETH?.toSignificant(4, {
                  groupSeparator: ","
                }) ?? "-"}{" "}
                ETH
              </Trans>
            )}
          </ThemedText.DeprecatedWhite>
        </RowBetween>
        <RowBetween>
          <ThemedText.DeprecatedWhite>
            <Trans>Pool rate</Trans>
          </ThemedText.DeprecatedWhite>
          <ThemedText.DeprecatedWhite>
            {stakingInfo ? (
              stakingInfo.active ? (
                <Trans>
                  {stakingInfo.totalRewardRate
                    ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                    ?.toFixed(0, { groupSeparator: "," })}{" "}
                  PANA / week
                </Trans>
              ) : (
                <Trans>0 PANA / week</Trans>
              )
            ) : (
              "-"
            )}
          </ThemedText.DeprecatedWhite>
        </RowBetween>
      </StatContainer>

      {isStaking && (
        <>
          <Break />
          <BottomSection showBackground={true}>
            <ThemedText.DeprecatedBlack
              color={"deprecated_white"}
              fontWeight={500}>
              <span>
                <Trans>Your rate</Trans>
              </span>
            </ThemedText.DeprecatedBlack>

            <ThemedText.DeprecatedBlack
              style={{ textAlign: "right" }}
              color={"deprecated_white"}
              fontWeight={500}>
              <span
                role="img"
                aria-label="wizard-icon"
                style={{ marginRight: "0.5rem" }}>
                ⚡
              </span>
              {stakingInfo ? (
                stakingInfo.active ? (
                  <Trans>
                    {stakingInfo.rewardRate
                      ?.multiply(BIG_INT_SECONDS_IN_WEEK)
                      ?.toSignificant(4, { groupSeparator: "," })}{" "}
                    PANA / week
                  </Trans>
                ) : (
                  <Trans>0 PANA / week</Trans>
                )
              ) : (
                "-"
              )}
            </ThemedText.DeprecatedBlack>
          </BottomSection>
        </>
      )}
    </Wrapper>
  );
}
