import { getHourlyRateData, getBulkPairData } from '../contexts/PairData';
import { healthClient } from '../apollo/client'
import { SUBGRAPH_HEALTH } from '../apollo/queries'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import {getEthPrice} from '../contexts/GlobalData'
import { client } from '../apollo/client'
import { TOKEN_DATA } from 'constants/chartData';

dayjs.extend(utc)

// Makes requests to CryptoCompare API
export async function makeApiRequest(path) {
    try {
        const response = await fetch(`https://min-api.cryptocompare.com/${path}`);
        return response.json();
    } catch (error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
}

// Generates a symbol ID from a pair of the coins
export function generateSymbol(exchange, fromSymbol, toSymbol) {
    const short = `${fromSymbol}/${toSymbol}`;
    return {
        short,
        full: `${exchange}:${short}`,
    };
}

export function parseFullSymbol(fullSymbol) {
    const match = fullSymbol.match(/^(\w+):(\w+)\/(\w+)$/);
    if (!match) {
        return null;
    }
    return { exchange: match[1], fromSymbol: match[2], toSymbol: match[3] };
}

async function getblock() {
    healthClient.query({
        query: SUBGRAPH_HEALTH,
    }).then((res) => {
        const syncedBlock = res.data.indexingStatusForCurrentVersion.chains[0].latestBlock.number
        if (syncedBlock ) {
           return syncedBlock
        }
    })
    .catch((e) => {
        console.log(e)
    })
}

export async function getData(from, to, resolution, name) {
    try {
       
        const pair = TOKEN_DATA[name].id

        const latestBlock = await getblock()


        let data = await getHourlyRateData(pair,from, to, latestBlock, resolution)
        let [newPrice, oneDayPrice, priceChange] = await getEthPrice(client)

        const data2 = await getBulkPairData([pair], newPrice)

        const base = TOKEN_DATA[name].base === 1 ? 0 : 1
        const reserve = TOKEN_DATA[name].base === 0 ? data2[0].reserve1/data2[0].reserve0 : data2[0].reserve0/data2[0].reserve1

        const formattedData = data[base]?.map((entry) => {
            return {
              time: parseFloat(entry.timestamp),
              open: parseFloat(entry.open),
              low: parseFloat(entry.open),
              close: parseFloat(entry.close),
              high: parseFloat(entry.close),
            }
          })
        
        if (formattedData && formattedData.length > 0) {
            formattedData.push({
                time: dayjs().unix(),
                open: parseFloat(formattedData[formattedData.length - 1].close),
                close: parseFloat(reserve),
                low: Math.min(parseFloat(reserve), parseFloat(formattedData[formattedData.length - 1].close)),
                high: Math.max(parseFloat(reserve), parseFloat(formattedData[formattedData.length - 1].close)),
            })
        }
        // if(formattedData.length === 300) {
        //     formattedData = formattedData.slice(0,299)
        // }
        // console.log(formattedData,formattedData.length,"dataaaaa");
        return formattedData;
    } catch (error) {
        throw new Error(`CryptoCompare request error: ${error.status}`);
    }
    // try {
    //     const response = useHourlyRateData(account, timeFrame);
    //     return response;
    // } catch(error) {
    //     throw new Error(`CryptoCompare request error: ${error.status}`);
    // }
}


// // try {
//     const response = useHourlyRateData(account, timeFrame);
//     return response;
// } catch(error) {
//     throw new Error(`CryptoCompare request error: ${error.status}`);
// }