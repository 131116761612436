import { Trans } from "@lingui/macro";
import {
  Currency,
  CurrencyAmount,
  Percent,
  TradeType
} from "@panaromafinance/panaromaswap_sdkcore";
import { useWeb3React } from "@web3-react/core";
import useAutoSlippageTolerance from "hooks/useAutoSlippageTolerance";
import { useBestTrade } from "hooks/useBestTrade";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ParsedQs } from "qs";
import { ReactNode, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { InterfaceTrade, TradeState } from "state/routing/types";
import { useUserSlippageToleranceWithDefault } from "state/user/hooks";

import { TOKEN_SHORTHANDS } from "../../constants/tokens";
import { useCurrency } from "../../hooks/Tokens";
import useENS from "../../hooks/useENS";
import useParsedQueryString from "../../hooks/useParsedQueryString";
import { isAddress } from "../../utils";
import { useCurrencyBalances } from "../connection/hooks";
import { AppState } from "../index";
import {
  Field,
  replaceSwapState,
  selectCurrency,
  setRecipient,
  switchCurrencies,
  typeInput
} from "./actions";
import { SwapState } from "./reducer";

export function useSwapState(): AppState["swap"] {
  return useAppSelector((state) => state.swap);
}

export function useSwapActionHandlers(): {
  onCurrencySelection: (field: Field, currency: Currency) => void;
  onSwitchTokens: () => void;
  onUserInput: (field: Field, typedValue: string) => void;
  onChangeRecipient: (recipient: string | null) => void;
} {
  // console.log("kkkk 11 value");

  const dispatch = useAppDispatch();
  const onCurrencySelection = useCallback(
    (field: Field, currency: Currency) => {
      dispatch(
        selectCurrency({
          field,
          currencyId: currency.isToken
            ? currency.address
            : currency.isNative
              ? "ETH"
              : ""
        })
      );
    },
    [dispatch]
  );

  const onSwitchTokens = useCallback(() => {
    dispatch(switchCurrencies());
  }, [dispatch]);

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }));
    },
    [dispatch]
  );

  const onChangeRecipient = useCallback(
    (recipient: string | null) => {
      dispatch(setRecipient({ recipient }));
    },
    [dispatch]
  );

  return {
    onSwitchTokens,
    onCurrencySelection,
    onUserInput,
    onChangeRecipient
  };
}

const BAD_RECIPIENT_ADDRESSES: { [address: string]: true } = {
  "0xFE86AEc59E6426B306d36dA0d483154B56F11ED3": true, // v1 factory
  "0x150170a4A4fA8A34dfC407f68CDE789E4dFfC103": true, // v1 factory
  "0x7E197ef9539CFBcA831729D1571df6b18A12e1Ed": true, // v1 router 01
  "0x7CB563d432797ec531B910e69b1156B2A1493115": true // v1 router 02
};

// from the current swap inputs, compute the best trade and return it.
export function useDerivedSwapInfo(): {
  currencies: { [field in Field]?: Currency | null };
  currencyBalances: { [field in Field]?: CurrencyAmount<Currency> };
  parsedAmount: CurrencyAmount<Currency> | undefined;
  inputError?: ReactNode;
  trade: {
    trade: InterfaceTrade<Currency, Currency, TradeType> | undefined;
    state: TradeState;
  };
  allowedSlippage: Percent;
} {
  const { account } = useWeb3React();

  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyId },
    [Field.OUTPUT]: { currencyId: outputCurrencyId },
    recipient
  } = useSwapState();

  const inputCurrency = useCurrency(inputCurrencyId);
  const outputCurrency = useCurrency(outputCurrencyId);
  const recipientLookup = useENS(recipient ?? undefined);
  const to: string | null =
    (recipient === null ? account : recipientLookup.address) ?? null;

  const relevantTokenBalances = useCurrencyBalances(
    account ?? undefined,
    useMemo(
      () => [inputCurrency ?? undefined, outputCurrency ?? undefined],
      [inputCurrency, outputCurrency]
    )
  );

  const isExactIn: boolean = independentField === Field.INPUT;
  const parsedAmount = useMemo(
    () =>
      tryParseCurrencyAmount(
        typedValue,
        (isExactIn ? inputCurrency : outputCurrency) ?? undefined
      ),
    [inputCurrency, isExactIn, outputCurrency, typedValue]
  );

  // console.log("kkkk parsedAmount", parsedAmount);
  

  const trade = useBestTrade(
    isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
    parsedAmount,
    (isExactIn ? outputCurrency : inputCurrency) ?? undefined
  );

  // console.log("kkkk parsedAmount trade", trade);


  const currencyBalances = useMemo(
    () => ({
      [Field.INPUT]: relevantTokenBalances[0],
      [Field.OUTPUT]: relevantTokenBalances[1]
    }),
    [relevantTokenBalances]
  );

  const currencies: { [field in Field]?: Currency | null } = useMemo(
    () => ({
      [Field.INPUT]: inputCurrency,
      [Field.OUTPUT]: outputCurrency
    }),
    [inputCurrency, outputCurrency]
  );

  // allowed slippage is either auto slippage, or custom user defined slippage if auto slippage disabled
  const autoSlippageTolerance = useAutoSlippageTolerance(trade.trade);
  const allowedSlippage = useUserSlippageToleranceWithDefault(
    autoSlippageTolerance
  );

  const inputError = useMemo(() => {
    let inputError: ReactNode | undefined;

    if (!account) {
      inputError = <Trans>Connect Wallet</Trans>;
    }

    if (!currencies[Field.INPUT] || !currencies[Field.OUTPUT]) {
      inputError = inputError ?? <Trans>Select a token</Trans>;
    }

    if (!parsedAmount) {
      inputError = inputError ?? <Trans>Enter an amount</Trans>;
    }

    const formattedTo = isAddress(to);
    if (!to || !formattedTo) {
      inputError = inputError ?? <Trans>Enter a recipient</Trans>;
    } else {
      if (BAD_RECIPIENT_ADDRESSES[formattedTo]) {
        inputError = inputError ?? <Trans>Invalid recipient</Trans>;
      }
    }

    // compare input balance to max input based on version
    const [balanceIn, amountIn] = [
      currencyBalances[Field.INPUT],
      trade.trade?.maximumAmountIn(allowedSlippage)
    ];

    if (balanceIn && amountIn && balanceIn.lessThan(amountIn)) {
      inputError = (
        <Trans>Insufficient {amountIn.currency.symbol} balance</Trans>
      );
    }

    return inputError;
  }, [
    account,
    allowedSlippage,
    currencies,
    currencyBalances,
    parsedAmount,
    to,
    trade.trade
  ]);

  return useMemo(
    () => ({
      currencies,
      currencyBalances,
      parsedAmount,
      inputError,
      trade,
      allowedSlippage
    }),
    [
      allowedSlippage,
      currencies,
      currencyBalances,
      inputError,
      parsedAmount,
      trade
    ]
  );
}

function parseCurrencyFromURLParameter(urlParam: ParsedQs[string]): string {
  if (typeof urlParam === "string") {
    const valid = isAddress(urlParam);
    if (valid) return valid;
    const upper = urlParam.toUpperCase();
    if (upper === "ETH") return "ETH";
    if (upper in TOKEN_SHORTHANDS) return upper;
  }
  return "";
}

function parseTokenAmountURLParameter(urlParam: any): string {
  return typeof urlParam === "string" && !isNaN(parseFloat(urlParam))
    ? urlParam
    : "";
}

function parseIndependentFieldURLParameter(urlParam: any): Field {
  return typeof urlParam === "string" && urlParam.toLowerCase() === "output"
    ? Field.OUTPUT
    : Field.INPUT;
}

const ENS_NAME_REGEX =
  /^[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?$/;
const ADDRESS_REGEX = /^0x[a-fA-F0-9]{40}$/;
function validatedRecipient(recipient: any): string | null {
  if (typeof recipient !== "string") return null;
  const address = isAddress(recipient);
  if (address) return address;
  if (ENS_NAME_REGEX.test(recipient)) return recipient;
  if (ADDRESS_REGEX.test(recipient)) return recipient;
  return null;
}

export function queryParametersToSwapState(parsedQs: ParsedQs): SwapState {
  let inputCurrency = parseCurrencyFromURLParameter(parsedQs.inputCurrency);
  let outputCurrency = parseCurrencyFromURLParameter(parsedQs.outputCurrency);
  const typedValue = parseTokenAmountURLParameter(parsedQs.exactAmount);
  const independentField = parseIndependentFieldURLParameter(
    parsedQs.exactField
  );

  if (
    inputCurrency === "" &&
    outputCurrency === "" &&
    typedValue === "" &&
    independentField === Field.INPUT
  ) {
    // Defaults to having the native currency selected
    inputCurrency = "ETH";
  } else if (inputCurrency === outputCurrency) {
    // clear output if identical
    outputCurrency = "";
  }

  const recipient = validatedRecipient(parsedQs.recipient);

  // console.log("1111 independentField", independentField);


  return {
    [Field.INPUT]: {
      currencyId: inputCurrency === "" ? null : inputCurrency ?? null
    },
    [Field.OUTPUT]: {
      currencyId: outputCurrency === "" ? null : outputCurrency ?? null
    },
    typedValue,
    independentField,
    recipient
  };
}

// updates the swap state to use the defaults for a given network
export function useDefaultsFromURLSearch(): SwapState {
  const { chainId } = useWeb3React();
  const dispatch = useAppDispatch();
  const parsedQs = useParsedQueryString();

  const parsedSwapState = useMemo(() => {
    return queryParametersToSwapState(parsedQs);
  }, [parsedQs]);

  useEffect(() => {
    if (!chainId) return;
    const inputCurrencyId =
      parsedSwapState[Field.INPUT].currencyId ?? undefined;
    // [TODO: Added JAMM tokenID to select JAMM on page load in swap]
    const outputCurrencyId =
      parsedSwapState[Field.OUTPUT].currencyId ? parsedSwapState[Field.OUTPUT].currencyId : "0x4e5E55bAeEf3bc747D22123cE4ADE3661c916a3e";
    
    dispatch(
      replaceSwapState({
        typedValue: parsedSwapState.typedValue,
        field: parsedSwapState.independentField,
        inputCurrencyId,
        outputCurrencyId,
        recipient: parsedSwapState.recipient
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, chainId]);

  return parsedSwapState;
}
