import { Trans } from "@lingui/macro";
import { Currency, CurrencyAmount, TradeType } from "@panaromafinance/panaromaswap_sdkcore";
import { useWeb3React } from "@web3-react/core";
import Badge from "components/Badge";
import { getChainInfo } from "constants/chainInfo";
import { SupportedL2ChainId } from "constants/chains";
import useCurrencyLogoURIs from "lib/hooks/useCurrencyLogoURIs";
import { ReactNode, useCallback, useContext, useState } from "react";
import {
  AlertCircle,
  AlertTriangle,
  ArrowUpCircle,
  CheckCircle
} from "react-feather";
import { Text } from "rebass";
import {
  useIsTransactionConfirmed,
  useTransaction
} from "state/transactions/hooks";
import styled, { ThemeContext } from "styled-components/macro";
import { isL2ChainId } from "utils/chains";

import Circle from "../../assets/images/blue-loader.svg";
import { ExternalLink } from "../../theme";
import { CloseIcon, CustomLightSpinner } from "../../theme";
import { ExplorerDataType, getExplorerLink } from "../../utils/getExplorerLink";
import { TransactionSummary } from "../AccountDetails/TransactionSummary";
import { ButtonLight, ButtonPrimary } from "../Button";
import { AutoColumn, ColumnCenter } from "../Column";
import Modal from "../Modal";
import { RowBetween, RowFixed } from "../Row";
import AnimatedConfirmation from "./AnimatedConfirmation";
import { FaFacebookF, FaTwitter, FaWhatsapp } from "react-icons/fa";
import { darken } from "polished";
import { InterfaceTrade } from "state/routing/types";
import { Field } from "state/mint/actions";

const Wrapper = styled.div`
  width: 100%;
  padding: 1rem;
`;
const Section = styled(AutoColumn)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? "0" : "0")};
`;

const BottomSection = styled(Section)`
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
`;

const ConfirmedIcon = styled(ColumnCenter)<{ inline?: boolean }>`
  padding: ${({ inline }) => (inline ? "20px 0" : "32px 0;")};
`;

const StyledLogo = styled.img`
  height: 16px;
  width: 16px;
  margin-left: 6px;
`;

const StyledExternalLink = styled(ExternalLink)`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.deprecated_text2};
  font-size: 1rem;
  width: fit-content;
  padding: 6px 0px;
  font-weight: 500;

  :hover,
  :focus {
    color: ${({ theme }) => darken(0.1, theme.deprecated_text1)};
    text-decoration: none;
  }
`;

function ConfirmationPendingContent({
  onDismiss,
  pendingText,
  inline
}: {
  onDismiss: () => void;
  pendingText: ReactNode;
  inline?: boolean; // not in modal
}) {
  return (
    <Wrapper>
      <AutoColumn gap="md">
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <CustomLightSpinner
            src={Circle}
            alt="loader"
            size={inline ? "40px" : "90px"}
          />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={"center"}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            <Trans>Waiting For Confirmation</Trans>
          </Text>
          <Text fontWeight={400} fontSize={16} textAlign="center">
            {pendingText}
          </Text>
          <Text
            fontWeight={500}
            fontSize={14}
            color="#565A69"
            textAlign="center"
            marginBottom="12px">
            <Trans>Confirm this transaction in your wallet</Trans>
          </Text>
        </AutoColumn>
      </AutoColumn>
    </Wrapper>
  );
}
function TransactionSubmittedContent({
  onDismiss,
  chainId,
  hash,
  currencyToAdd,
  inline,
  transactionType,
  trade,
  currencies,
  currencyA,
  currencyB
}: {
  onDismiss: () => void;
  hash: string | undefined;
  chainId: number;
  currencyToAdd?: Currency | undefined;
  inline?: boolean; // not in modal
  transactionType?: string | undefined;
  trade?: InterfaceTrade<Currency, Currency, TradeType> | undefined;
  currencies?: { [field in Field]?: Currency } | undefined;
  currencyA?: Currency | null | undefined;
  currencyB?: Currency | null | undefined;
}) {
  const theme = useContext(ThemeContext);

  const { connector } = useWeb3React();

  const token = currencyToAdd?.wrapped;
  const logoURL = useCurrencyLogoURIs(token)[0];

  const [success, setSuccess] = useState<boolean | undefined>();

  const addToken = useCallback(() => {
    if (!token?.symbol || !connector.watchAsset) return;
    connector
      .watchAsset({
        address: token.address,
        symbol: token.symbol,
        decimals: token.decimals,
        image: logoURL
      })
      .then(() => setSuccess(true))
      .catch(() => setSuccess(false));
  }, [connector, logoURL, token]);

  let hashstr = hash;
  const hashchar = hash?.slice(0, 2);
  if(hashchar !== "0x") hashstr = "0x" + hash;

  return (
    <Wrapper>
      <Section inline={inline}>
        {!inline && (
          <RowBetween>
            <div />
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          <ArrowUpCircle
            strokeWidth={0.5}
            size={inline ? "40px" : "90px"}
            color={theme.deprecated_primary1}
          />
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={"center"}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            <Trans>Transaction Submitted</Trans>
          </Text>
          {chainId && hash && (
            <ExternalLink id="transaction-view"
              href={getExplorerLink(
                chainId,
                hashstr!,
                ExplorerDataType.TRANSACTION
              )}>
              <Text
                fontWeight={500}
                fontSize={14}
                color={theme.deprecated_primary1}>
                <Trans>View on Explorer</Trans>
              </Text>
            </ExternalLink>
          )}
          {currencyToAdd && connector.watchAsset && (
            <ButtonLight
              mt="12px"
              padding="6px 12px"
              width="fit-content"
              onClick={addToken}>
              {!success ? (
                <RowFixed>
                  <Trans>Add {currencyToAdd.symbol}</Trans>
                </RowFixed>
              ) : (
                <RowFixed>
                  <Trans>Added {currencyToAdd.symbol} </Trans>
                  <CheckCircle
                    size={"16px"}
                    stroke={theme.deprecated_green1}
                    style={{ marginLeft: "6px" }}
                  />
                </RowFixed>
              )}
            </ButtonLight>
          )}
          {
            transactionType === "SWAP" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20swapped%20${trade?.inputAmount.toSignificant(6)}%20${trade?.inputAmount.currency.symbol}%20to%20${trade?.outputAmount.currency.symbol}%20on%20Panaroma%20Swap%20Exchange%20&url=https://swap.panaroma.finance/&hashtags=panaromafinance,swap`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20swapped%20${trade?.inputAmount.toSignificant(6)}%20${trade?.inputAmount.currency.symbol}%20to%20${trade?.outputAmount.currency.symbol}%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : transactionType === "ADDLIQUIDITY" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20added%20Liquidity%20for%20${currencies![Field.CURRENCY_A]?.symbol}-${currencies![Field.CURRENCY_B]?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20&url=https://swap.panaroma.finance/&hashtags=panaromafinance,addliquidity`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20added%20Liquidity%20for%20${currencies![Field.CURRENCY_A]?.symbol}-${currencies![Field.CURRENCY_B]?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23addliquidity%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : transactionType === "LOCKLIQUIDITY" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20locked%20Liquidity%20for%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20&url=https://panaroma.finance/&hashtags=panaromafinance,swap`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20locked%20Liquidity%20for%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : transactionType === "WITHDRAW" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20did%20a%20withdraw%20from%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20&url=https://panaroma.finance/&hashtags=panaromafinance,swap`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20did%20a%20withdraw%20from%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : transactionType === "REFERRAL" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={"http://twitter.com/share?text=Hi,%20I%20just%20did%20referral%20registration%20on%20Panaroma%20Swap%20Exchange%20&url=https://panaroma.finance/&hashtags=panaromafinance,swap"}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://wa.me/?text=Hi,%20I%20just%20did%20referral%20registration%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance"}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : transactionType === "REMOVE" ? (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20removed%20Liquidity%20for%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20&url=https://panaroma.finance/&hashtags=panaromafinance,swap`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20removed%20Liquidity%20for%20${currencyA?.symbol}-${currencyB?.symbol}%20pair%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            ) : (
              <div className="d-flex justify-content-around w-100 mt-1">
                <StyledExternalLink href={`http://twitter.com/share?text=Hi,%20I%20just%20withdrawn%20referral%20amount%20on%20Panaroma%20Swap%20Exchange%20&url=https://panaroma.finance/&hashtags=panaromafinance,swap`}>
                  <ButtonLight>
                        <FaTwitter/>
                  </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={"https://www.facebook.com/sharer/sharer.php?u=https://panaroma.finance/"}>
                    <ButtonLight>
                          <FaFacebookF/>
                    </ButtonLight>
                </StyledExternalLink>
                <StyledExternalLink href={`https://wa.me/?text=Hi,%20I%20just%20withdrawn%20referral%20amount%20on%20Panaroma%20Swap%20Exchange%20https://panaroma.finance%20%23swap%20%23panaromafinance`}>
                    <ButtonLight>
                          <FaWhatsapp/>
                    </ButtonLight>
                </StyledExternalLink>
              </div>
            )
          }
          <ButtonPrimary onClick={onDismiss} id="transaction-close">
            <Text fontWeight={500} fontSize={20}>
              {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  );
}

export function ConfirmationModalContent({
  title,
  bottomContent,
  onDismiss,
  topContent
}: {
  title: ReactNode;
  onDismiss: () => void;
  topContent: () => ReactNode;
  bottomContent?: () => ReactNode | undefined;
}) {
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={16}>
            {title}
          </Text>
          <CloseIcon onClick={onDismiss} data-cy="confirmation-close-icon" />
        </RowBetween>
      </Section>
      <Section>
        {topContent()}
      </Section>
      {bottomContent && (
        <BottomSection gap="12px">{bottomContent()}</BottomSection>
      )}
    </Wrapper>
  );
}

export function TransactionErrorContent({
  message,
  onDismiss
}: {
  message: ReactNode;
  onDismiss: () => void;
}) {
  const theme = useContext(ThemeContext);
  return (
    <Wrapper>
      <Section>
        <RowBetween>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Error</Trans>
          </Text>
          <CloseIcon onClick={onDismiss} />
        </RowBetween>
        <AutoColumn
          style={{ marginTop: 20, padding: "2rem 0" }}
          gap="24px"
          justify="center">
          <AlertTriangle
            color={theme.deprecated_red1}
            style={{ strokeWidth: 1.5 }}
            size={64}
          />
          <Text
            fontWeight={500}
            fontSize={16}
            color={theme.deprecated_red1}
            style={{
              textAlign: "center",
              width: "85%",
              wordBreak: "break-word"
            }}>
            {message}
          </Text>
        </AutoColumn>
      </Section>
      <BottomSection gap="12px">
        <ButtonPrimary onClick={onDismiss} id="confirm-dismiss">
          <Trans>Dismiss</Trans>
        </ButtonPrimary>
      </BottomSection>
    </Wrapper>
  );
}

function L2Content({
  onDismiss,
  chainId,
  hash,
  pendingText,
  inline
}: {
  onDismiss: () => void;
  hash: string | undefined;
  chainId: SupportedL2ChainId;
  currencyToAdd?: Currency | undefined;
  pendingText: ReactNode;
  inline?: boolean; // not in modal
}) {
  const theme = useContext(ThemeContext);

  const transaction = useTransaction(hash);
  const confirmed = useIsTransactionConfirmed(hash);
  const transactionSuccess = transaction?.receipt?.status === 1;

  // convert unix time difference to seconds
  const secondsToConfirm = transaction?.confirmedTime
    ? (transaction.confirmedTime - transaction.addedTime) / 1000
    : undefined;

  const info = getChainInfo(chainId);

  // console.log(transactionSuccess,"transactionSuccess");

  let hashstr = hash;
  const hashchar = hash?.slice(0, 2);
  if(hashchar !== "0x") hashstr = "0x" + hash;
  

  return (
    <Wrapper>
      <Section inline={inline}>
        {!inline && (
          <RowBetween mb="16px">
            <Badge>
              <RowFixed>
                <StyledLogo
                  src={info.logoUrl}
                  style={{ margin: "0 8px 0 0" }}
                />
                {info.label}
              </RowFixed>
            </Badge>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
        )}
        <ConfirmedIcon inline={inline}>
          {confirmed ? (
            transactionSuccess ? (
              // <CheckCircle strokeWidth={1} size={inline ? '40px' : '90px'} color={theme.deprecated_green1} />
              <AnimatedConfirmation />
            ) : (
              <AlertCircle
                strokeWidth={1}
                size={inline ? "40px" : "90px"}
                color={theme.deprecated_red1}
              />
            )
          ) : (
            <CustomLightSpinner
              src={Circle}
              alt="loader"
              size={inline ? "40px" : "90px"}
            />
          )}
        </ConfirmedIcon>
        <AutoColumn gap="12px" justify={"center"}>
          <Text fontWeight={500} fontSize={20} textAlign="center">
            {!hash ? (
              <Trans>Confirm transaction in wallet</Trans>
            ) : !confirmed ? (
              <Trans>Transaction Submitted</Trans>
            ) : transactionSuccess ? (
              <Trans>Success</Trans>
            ) : (
              <Trans>Error</Trans>
            )}
          </Text>
          <Text fontWeight={400} fontSize={16} textAlign="center">
            {transaction ? (
              <TransactionSummary info={transaction.info} success={transactionSuccess}/>
            ) : (
              pendingText
            )}
          </Text>
          {chainId && hash ? (
            <ExternalLink id="transaction-view"
              href={getExplorerLink(
                chainId,
                hashstr!,
                ExplorerDataType.TRANSACTION
              )}>
              <Text
                fontWeight={500}
                fontSize={14}
                color={theme.deprecated_primary1}>
                <Trans>View on Explorer</Trans>
              </Text>
            </ExternalLink>
          ) : (
            <div style={{ height: "17px" }} />
          )}
          <Text
            color={theme.deprecated_text3}
            style={{ margin: "20px 0 0 0" }}
            fontSize={"14px"}>
            {!secondsToConfirm ? (
              <div style={{ height: "24px" }} />
            ) : (
              <div>
                <Trans>Transaction completed in </Trans>
                <span
                  style={{
                    fontWeight: 500,
                    marginLeft: "4px",
                    color: theme.deprecated_text1
                  }}>
                  {secondsToConfirm} seconds 🎉
                </span>
              </div>
            )}
          </Text>
          <ButtonPrimary onClick={onDismiss} style={{ margin: "4px 0 0 0" }}>
            <Text fontWeight={500} fontSize={20}>
              {inline ? <Trans>Return</Trans> : <Trans>Close</Trans>}
            </Text>
          </ButtonPrimary>
        </AutoColumn>
      </Section>
    </Wrapper>
  );
}

interface ConfirmationModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  hash: string | undefined;
  content: () => ReactNode;
  attemptingTxn: boolean;
  pendingText: ReactNode;
  currencyToAdd?: Currency | undefined;
  transactionType?: string | undefined;
  trade?: InterfaceTrade<Currency, Currency, TradeType> | undefined ;
  currencies?: { [field in Field]?: Currency } | undefined;
  currencyA?: Currency | null | undefined;
  currencyB?: Currency | null | undefined;
}

export default function TransactionConfirmationModal({
  isOpen,
  onDismiss,
  attemptingTxn,
  hash,
  pendingText,
  content,
  currencyToAdd,
  transactionType,
  trade,
  currencies,
  currencyA,
  currencyB
}: ConfirmationModalProps) {
  const { chainId } = useWeb3React();

  if (!chainId) return null;

  // confirmation screen
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      {isL2ChainId(chainId) && (hash || attemptingTxn) ? (
        <L2Content
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      ) : attemptingTxn ? (
        <ConfirmationPendingContent
          onDismiss={onDismiss}
          pendingText={pendingText}
        />
      ) : hash ? (
        <TransactionSubmittedContent
          chainId={chainId}
          hash={hash}
          onDismiss={onDismiss}
          currencyToAdd={currencyToAdd}
          transactionType={transactionType}
          trade={trade}
          currencies={currencies}
          currencyA={currencyA}
          currencyB={currencyB}
        />
      ) : (
        content()
      )}
    </Modal>
  );
}