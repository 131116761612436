import { Trans } from "@lingui/macro";
import { Currency, CurrencyAmount, Percent, Token } from "@panaromafinance/panaromaswap_sdkcore";
import { Pair } from "@panaromafinance/panaromaswap_v1sdk";
import { useWeb3React } from "@web3-react/core";
import {
  ElementName,
  Event,
  EventName
} from "components/AmplitudeAnalytics/constants";
import { TraceEvent } from "components/AmplitudeAnalytics/TraceEvent";
import { AutoColumn } from "components/Column";
import {
  LoadingOpacityContainer,
  loadingOpacityMixin
} from "components/Loader/styled";
import { isSupportedChain } from "constants/chains";
import { darken } from "polished";
import { ReactNode, useCallback, useState } from "react";
import { Lock } from "react-feather";
import styled from "styled-components/macro";
import { formatCurrencyAmount } from "utils/formatCurrencyAmount";

import { ReactComponent as DropDown } from "../../assets/images/dropdown.svg";
import useTheme from "../../hooks/useTheme";
import { useCurrencyBalance } from "../../state/connection/hooks";
import { ThemedText } from "../../theme";
import { ButtonGray } from "../Button";
import CurrencyLogo from "../CurrencyLogo";
import DoubleCurrencyLogo from "../DoubleLogo";
import { Input as NumericalInput } from "../NumericalInput";
import { RowBetween, RowFixed } from "../Row";
import CurrencySearchModal from "../SearchModal/CurrencySearchModal";
import { FiatValue } from "./FiatValue";
import { MdClose } from "react-icons/md";

const InputPanel = styled.div<{ hideInput?: boolean }>`
  ${({ theme }) => theme.flexColumnNoWrap}
  position: relative;
  border-radius: ${({ hideInput }) => (hideInput ? "16px" : "20px")};
  background-color: ${({ theme, hideInput }) =>
    hideInput ? "transparent" : theme.deprecated_bg2};
  z-index: 1;
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};
  transition: height 1s ease;
  will-change: height;
`;

const FixedContainer = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 20px;
  background-color: ${({ theme }) => theme.deprecated_bg2};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`;

const Container = styled.div<{ hideInput: boolean; disabled: boolean }>`
  border-radius: ${({ hideInput }) => (hideInput ? "16px" : "20px")};
  border: 1px solid ${({ theme }) => theme.deprecated_bg0};
  background-color: ${({ theme }) => theme.deprecated_bg1};
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};
  ${({ theme, hideInput, disabled }) =>
    !disabled &&
    `
    :focus,
    :hover {
      border: 1px solid ${hideInput ? " transparent" : theme.deprecated_bg3};
    }
  `}
`;

const CurrencySelect = styled(ButtonGray) <{
  visible: boolean;
  selected: boolean;
  hideInput?: boolean;
  disabled?: boolean;
}>`
  align-items: center;
  background-color: ${({ selected, theme }) =>
    selected ? theme.deprecated_bg2 : theme.deprecated_primary1};
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  box-shadow: ${({ selected }) =>
    selected ? "none" : "0px 6px 10px rgba(0, 0, 0, 0.075)"};
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
  color: ${({ selected, theme }) =>
    selected ? theme.deprecated_text1 : theme.deprecated_white};
  cursor: pointer;
  border-radius: 16px;
  outline: none;
  user-select: none;
  border: none;
  font-size: 24px;
  font-weight: 500;
  height: ${({ hideInput }) => (hideInput ? "2.8rem" : "2.4rem")};
  width: ${({ hideInput }) => (hideInput ? "100%" : "initial")};
  padding: 0 8px;
  justify-content: space-between;
  margin-left: ${({ hideInput }) => (hideInput ? "0" : "12px")};
  :focus,
  :hover {
    background-color: ${({ selected, theme }) =>
    selected
      ? theme.deprecated_bg3
      : darken(0.05, theme.deprecated_primary1)};
  }
  visibility: ${({ visible }) => (visible ? "visible" : "hidden")};
`;

const InputRow = styled.div<{ selected: boolean }>`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  justify-content: space-between;
  padding: ${({ selected }) =>
    selected ? " 1rem 1rem 0.75rem 1rem" : "1rem 1rem 1rem 1rem"};
`;

const LabelRow = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: center;
  color: ${({ theme }) => theme.deprecated_text1};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0 1rem 1rem;
  span:hover {
    cursor: pointer;
    color: ${({ theme }) => darken(0.2, theme.deprecated_text2)};
  }
`;

const FiatRow = styled(LabelRow) <{ withdraw?: boolean }>`
  justify-content: flex-end;
  height: 16px;

  margin-top: ${({ withdraw }) => withdraw ? "-0.5rem" : ""};
  ${({ theme,withdraw }) => theme.mediaWidth.upToExtraSmall`
    margin-top: ${withdraw ? "1rem" : ""};
  `};
`;

const Aligner = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledDropDown = styled(DropDown) <{ selected: boolean }>`
  margin: 0 0.25rem 0 0.35rem;
  height: 35%;

  path {
    stroke: ${({ selected, theme }) =>
    selected ? theme.deprecated_text1 : theme.deprecated_white};
    stroke-width: 1.5px;
  }
`;

const StyledTokenName = styled.span<{ active?: boolean }>`
  ${({ active }) =>
    active
      ? "  margin: 0 0.25rem 0 0.25rem;"
      : "  margin: 0 0.25rem 0 0.25rem;"}
  font-size:  ${({ active }) => (active ? "18px" : "18px")};
`;

const StyledBalanceMax = styled.button<{ disabled?: boolean }>`
  background-color: transparent;
  background-color: ${({ theme }) => theme.deprecated_primary5};
  border: none;
  border-radius: 12px;
  color: ${({ theme }) => theme.deprecated_primary1};
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 0.25rem;
  opacity: ${({ disabled }) => (!disabled ? 1 : 0.4)};
  padding: 4px 6px;
  pointer-events: ${({ disabled }) => (!disabled ? "initial" : "none")};

  :hover {
    opacity: ${({ disabled }) => (!disabled ? 0.8 : 0.4)};
  }

  :focus {
    outline: none;
  }
`;

const StyledNumericalInput = styled(NumericalInput) <{ $loading: boolean }>`
  ${loadingOpacityMixin};
  text-align: left;
`;

interface CurrencyInputPanelProps {
  value: string;
  onUserInput: (value: string) => void;
  onMax?: () => void;
  showMaxButton: boolean;
  label?: ReactNode;
  onCurrencySelect?: (currency: Currency) => void;
  currency?: Currency | null;
  hideBalance?: boolean;
  pair?: Pair | null;
  hideInput?: boolean;
  otherCurrency?: Currency | null;
  fiatValue?: CurrencyAmount<Token> | null;
  priceImpact?: Percent;
  id: string;
  showCommonBases?: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  renderBalance?: (amount: CurrencyAmount<Currency>) => ReactNode;
  locked?: boolean;
  loading?: boolean;
  showAllowance?: boolean;
  allowanceValue?: number;
  onClearAllowance?: () => void;
  showClear?: boolean;
  withdraw?: boolean;
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  onCurrencySelect,
  currency,
  otherCurrency,
  id,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  renderBalance,
  fiatValue,
  priceImpact,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  locked = false,
  loading = false,
  showAllowance,
  allowanceValue,
  onClearAllowance,
  showClear,
  withdraw,
  ...rest
}: CurrencyInputPanelProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const { account, chainId } = useWeb3React();
  const selectedCurrencyBalance = useCurrencyBalance(
    account ?? undefined,
    currency ?? undefined
  );
  const theme = useTheme();

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false);
  }, [setModalOpen]);

  const chainAllowed = isSupportedChain(chainId);

  return (
    <InputPanel id={id} hideInput={hideInput} {...rest}>
      {locked && (
        <FixedContainer>
          <AutoColumn gap="sm" justify="center">
            <Lock />
            <ThemedText.DeprecatedLabel
              fontSize="12px"
              textAlign="center"
              padding="0 12px">
              <Trans>
                The market price is outside your specified price range.
                Single-asset deposit only.
              </Trans>
            </ThemedText.DeprecatedLabel>
          </AutoColumn>
        </FixedContainer>
      )}
      <Container hideInput={hideInput} disabled={!chainAllowed}>
        <InputRow
          style={hideInput ? { padding: "0", borderRadius: "8px" } : {}}
          selected={!onCurrencySelect}>
          {!hideInput && (
            <StyledNumericalInput
              className="token-amount-input"
              value={value}
              onUserInput={onUserInput}
              disabled={!chainAllowed}
              $loading={loading}
            />
          )}
          {
            !withdraw ? (
              <CurrencySelect
                disabled={!chainAllowed}
                visible={currency !== undefined}
                selected={!!currency}
                hideInput={hideInput}
                className="open-currency-select-button"
                onClick={() => {
                  if (onCurrencySelect) {
                    setModalOpen(true);
                  }
                }}>
                <Aligner>
                  <RowFixed>
                    {pair ? (
                      <span style={{ marginRight: "0.5rem" }}>
                        <DoubleCurrencyLogo
                          currency0={pair.token0}
                          currency1={pair.token1}
                          size={24}
                          margin={true}
                        />
                      </span>
                    ) : currency ? (
                      <CurrencyLogo
                        style={{ marginRight: "0.5rem" }}
                        currency={currency}
                        size={"24px"}
                      />
                    ) : null}

                    {pair ? (
                      <StyledTokenName className="pair-name-container">
                        {pair?.token0.symbol}:{pair?.token1.symbol}
                      </StyledTokenName>
                    ) : (
                      //heerreeeee
                      <StyledTokenName
                        className="token-symbol-container"
                        active={Boolean(currency && currency.symbol)}>
                        {(currency && currency.symbol && currency.symbol.length > 20
                          ? currency.symbol.slice(0, 4) +
                          "..." +
                          currency.symbol.slice(
                            currency.symbol.length - 5,
                            currency.symbol.length
                          )
                          : currency?.symbol) || <Trans>Select a token</Trans>}
                      </StyledTokenName>
                    )}
                  </RowFixed>
                  {onCurrencySelect && <StyledDropDown selected={!!currency} />}
                </Aligner>
              </CurrencySelect>
            ): null
          }

        </InputRow>
        {
          withdraw ? (
            <CurrencySelect
              disabled={!chainAllowed}
              visible={currency !== undefined}
              selected={!!currency}
              hideInput={hideInput}
              className="open-currency-select-button"
              onClick={() => {
                if (onCurrencySelect) {
                  setModalOpen(true);
                }
              }}
            >
              <Aligner>
                <RowFixed>
                  {pair ? (
                    <span style={{ marginRight: "0.5rem" }}>
                      <DoubleCurrencyLogo
                        currency0={pair.token0}
                        currency1={pair.token1}
                        size={24}
                        margin={true}
                      />
                    </span>
                  ) : currency ? (
                    <CurrencyLogo
                      style={{ marginRight: "0.5rem" }}
                      currency={currency}
                      size={"24px"}
                    />
                  ) : null}

                  {pair ? (
                    <StyledTokenName className="pair-name-container">
                      {pair?.token0.symbol}:{pair?.token1.symbol}
                    </StyledTokenName>
                  ) : (
                    //heerreeeee
                    <StyledTokenName
                      className="token-symbol-container"
                      active={Boolean(currency && currency.symbol)}>
                      {(currency && currency.symbol && currency.symbol.length > 20
                        ? currency.symbol.slice(0, 4) +
                        "..." +
                        currency.symbol.slice(
                          currency.symbol.length - 5,
                          currency.symbol.length
                        )
                        : currency?.symbol) || <Trans>Select a token</Trans>}
                    </StyledTokenName>
                  )}
                </RowFixed>
              </Aligner>
            </CurrencySelect>
          ):null
        }
        {!hideInput && !hideBalance && currency && (
          <FiatRow withdraw={withdraw}>
            <RowBetween>
              {
                showAllowance ? (
                  <RowFixed>
                    <ThemedText.DeprecatedBody
                      color={theme.deprecated_text3}
                      fontWeight={500}
                      fontSize={14}
                      style={{ display: "inline", cursor: "pointer" }}
                    >
                      <Trans>
                        Allowance:{" "}{allowanceValue}
                      </Trans>
                    </ThemedText.DeprecatedBody>
                    <TraceEvent
                      events={[Event.onClick]}
                      name={EventName.ALLOWANCE_CLEAR}
                      element={ElementName.ALLOWANCE_CLEAR}>
                      {
                        showClear ? (
                          <StyledBalanceMax onClick={onClearAllowance} className="d-flex align-items-center gap-1">
                            <Trans>CLEAR</Trans> <MdClose/>
                          </StyledBalanceMax>
                        ): null
                      }
                    </TraceEvent>
                  </RowFixed>
                ) : null
              }
              <LoadingOpacityContainer $loading={loading}>
                <FiatValue fiatValue={fiatValue} priceImpact={priceImpact} />
              </LoadingOpacityContainer>
              {account ? (
                <RowFixed style={{ height: "17px" }}>
                  <ThemedText.DeprecatedBody
                    onClick={onMax}
                    color={theme.deprecated_text3}
                    fontWeight={500}
                    fontSize={14}
                    style={{ display: "inline", cursor: "pointer" }}>
                    {!hideBalance && currency && selectedCurrencyBalance ? (
                      renderBalance ? (
                        renderBalance(selectedCurrencyBalance)
                      ) : (
                        <Trans>
                          Balance:{" "}
                          {formatCurrencyAmount(selectedCurrencyBalance, 4)}
                        </Trans>
                      )
                    ) : null}
                  </ThemedText.DeprecatedBody>
                  {showMaxButton && selectedCurrencyBalance ? (
                    <TraceEvent
                      events={[Event.onClick]}
                      name={EventName.SWAP_MAX_TOKEN_AMOUNT_SELECTED}
                      element={ElementName.MAX_TOKEN_AMOUNT_BUTTON}>
                      <StyledBalanceMax onClick={onMax}>
                        <Trans>MAX</Trans>
                      </StyledBalanceMax>
                    </TraceEvent>
                  ) : null}
                </RowFixed>
              ) : (
                <span />
              )}
            </RowBetween>
          </FiatRow>
        )}
      </Container>
      {onCurrencySelect && (
        <CurrencySearchModal
          isOpen={modalOpen}
          onDismiss={handleDismissSearch}
          onCurrencySelect={onCurrencySelect}
          selectedCurrency={currency}
          otherSelectedCurrency={otherCurrency}
          showCommonBases={showCommonBases}
          showCurrencyAmount={showCurrencyAmount}
          disableNonToken={disableNonToken}
        />
      )}
    </InputPanel>
  );
}