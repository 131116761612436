import { BigNumber } from "@ethersproject/bignumber";
import { JsonRpcProvider, TransactionResponse } from "@ethersproject/providers";
// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import { Trade } from "@panaromafinance/panaromaswap_routersdk";
import { Currency, TradeType } from "@panaromafinance/panaromaswap_sdkcore";
import { Trade as V1Trade } from "@panaromafinance/panaromaswap_v1sdk";
import { Trade as V2edgeTrade } from "@panaromafinance/panaromaswap_v2edgesdk";
import { useMemo } from "react";
import { calculateGasMargin } from "utils/calculateGasMargin";
import isZero from "utils/isZero";
import { swapErrorToUserReadableMessage } from "utils/swapErrorToUserReadableMessage";

type AnyTrade =
  | V1Trade<Currency, Currency, TradeType>
  | V2edgeTrade<Currency, Currency, TradeType>
  | Trade<Currency, Currency, TradeType>;

interface SwapCall {
  address: string;
  calldata: string;
  value: string;
}

interface SwapCallEstimate {
  call: SwapCall;
}

interface SuccessfulCall extends SwapCallEstimate {
  call: SwapCall;
  gasEstimate: BigNumber;
}

interface FailedCall extends SwapCallEstimate {
  call: SwapCall;
  error: Error;
}

// returns a function that will execute a swap, if the parameters are all valid
export default function useSendSwapTransaction(
  account: string | null | undefined,
  chainId: number | undefined,
  provider: JsonRpcProvider | undefined,
  trade: AnyTrade | undefined, // trade to execute, required
  swapCalls: SwapCall[]
): { callback: null | (() => Promise<TransactionResponse>) } {
  return useMemo(() => {
    if (!trade || !provider || !account || !chainId) {
      return { callback: null };
    }
    return {
      callback: async function onSwap(): Promise<TransactionResponse> {
        const estimatedCalls: SwapCallEstimate[] = await Promise.all(
          swapCalls.map((call) => {
            const { address, calldata, value } = call;
            // console.log("3323232", address, calldata, value);
            const tx =
              !value || isZero(value)
                ? { from: account, to: address, data: calldata }
                : {
                  from: account,
                  to: address,
                  data: calldata,
                  value
                };

            // console.log("+++++ tx", tx);

            return provider
              .estimateGas(tx)
              .then((gasEstimate) => {
                return {
                  call,
                  gasEstimate
                };
              })
              .catch((gasError) => {
                console.debug(
                  "Gas estimate failed, trying eth_call to extract error",
                  call
                );

                return provider
                  .call(tx)
                  .then((result) => {
                    console.debug(
                      "Unexpected successful call after failed estimate gas",
                      call,
                      gasError,
                      result
                    );
                    return {
                      call,
                      error: (
                        <Trans>
                          Unexpected issue with estimating the gas. Please try
                          again.
                        </Trans>
                      )
                    };
                  })
                  .catch((callError) => {
                    console.debug("Call threw error", call, callError);
                    return {
                      call,
                      error: swapErrorToUserReadableMessage(callError)
                    };
                  });
              });
          })
        );

        // a successful estimation is a bignumber gas estimate and the next call is also a bignumber gas estimate
        let bestCallOption: SuccessfulCall | SwapCallEstimate | undefined =
          estimatedCalls.find(
            (el, ix, list): el is SuccessfulCall =>
              "gasEstimate" in el &&
              (ix === list.length - 1 || "gasEstimate" in list[ix + 1])
          );

        // check if any calls errored with a recognizable error
        if (!bestCallOption) {
          const errorCalls = estimatedCalls.filter(
            (call): call is FailedCall => "error" in call
          );
          if (errorCalls.length > 0)
            throw errorCalls[errorCalls.length - 1].error;
          const firstNoErrorCall = estimatedCalls.find<SwapCallEstimate>(
            (call): call is SwapCallEstimate => !("error" in call)
          );
          if (!firstNoErrorCall)
            throw new Error(
              t`Unexpected error. Could not estimate gas for the swap.`
            );
          bestCallOption = firstNoErrorCall;
        }

        const {
          call: { address, calldata, value }
        } = bestCallOption;
        // console.log(call)

        return provider
          .getSigner()
          .sendTransaction({
            from: account,
            to: address,
            data: calldata,
            // let the wallet try if we can't estimate the gas
            ...("gasEstimate" in bestCallOption
              ? { gasLimit: calculateGasMargin(bestCallOption.gasEstimate) }
              : {}),
            ...(value && !isZero(value) ? { value } : {})
          })
          .then((response) => {
            return response;
          })
          .catch((error) => {
            if(swapErrorToUserReadableMessage(error).props.values[0]?.includes("invalid hash")) {
              return {"hash": error.transactionHash} as TransactionResponse
            } else {
              // if the user rejected the tx, pass this along
              if (error?.code === 4001) {
                throw new Error(t`Transaction rejected.`);
              } else {
                // otherwise, the error was unexpected and we need to convey that
                console.error(`Swap failed`, error, address, calldata, value);
                // console.log("321321 swapErrorToUserReadableMessage(error)",error, swapErrorToUserReadableMessage(error));
  
                throw new Error(
                  `Swap failed: ${swapErrorToUserReadableMessage(error).props.id}`
                );
              }
            }
          });
      }
    };
  }, [account, chainId, provider, swapCalls, trade]);
}
