import { TransactionResponse } from "@ethersproject/providers";
import { Trans } from "@lingui/macro";
import StakingRewardsJson from "@panaromafinance/panaromaswap_liquiditystaker/build/StakingRewards.json";
import { CurrencyAmount, Token } from "@panaromafinance/panaromaswap_sdkcore";
import { Pair } from "@panaromafinance/panaromaswap_v1sdk";
import { useWeb3React } from "@web3-react/core";
import { useV1LiquidityTokenPermit } from "hooks/useV1LiquidityTokenPermit";
import { useCallback, useState } from "react";
import styled from "styled-components/macro";

import {
  ApprovalState,
  useApproveCallback
} from "../../hooks/useApproveCallback";
import {
  useContract,
  usePairContract,
  useV1RouterContract
} from "../../hooks/useContract";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { StakingInfo, useDerivedStakeInfo } from "../../state/stake/hooks";
import { useTransactionAdder } from "../../state/transactions/hooks";
import { TransactionType } from "../../state/transactions/types";
import { CloseIcon, ThemedText } from "../../theme";
import { formatCurrencyAmount } from "../../utils/formatCurrencyAmount";
import { maxAmountSpend } from "../../utils/maxAmountSpend";
import { ButtonConfirmed, ButtonError } from "../Button";
import { AutoColumn } from "../Column";
import CurrencyInputPanel from "../CurrencyInputPanel";
import Modal from "../Modal";
import { LoadingView, SubmittedView } from "../ModalViews";
import ProgressCircles from "../ProgressSteps";
import { RowBetween } from "../Row";

const { abi: STAKING_REWARDS_ABI } = StakingRewardsJson;

function useStakingContract(
  stakingAddress?: string,
  withSignerIfPossible?: boolean
) {
  return useContract(stakingAddress, STAKING_REWARDS_ABI, withSignerIfPossible);
}

const HypotheticalRewardRate = styled.div<{ dim: boolean }>`
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-left: 20px;

  opacity: ${({ dim }) => (dim ? 0.5 : 1)};
`;

const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`;

interface StakingModalProps {
  isOpen: boolean;
  onDismiss: () => void;
  stakingInfo: StakingInfo;
  userLiquidityUnstaked: CurrencyAmount<Token> | undefined;
}

export default function StakingModal({
  isOpen,
  onDismiss,
  stakingInfo,
  userLiquidityUnstaked
}: StakingModalProps) {
  const { provider } = useWeb3React();

  // track and parse user input
  const [typedValue, setTypedValue] = useState("");
  const { parsedAmount, error } = useDerivedStakeInfo(
    typedValue,
    stakingInfo.stakedAmount.currency,
    userLiquidityUnstaked
  );
  const parsedAmountWrapped = parsedAmount?.wrapped;

  let hypotheticalRewardRate: CurrencyAmount<Token> =
    CurrencyAmount.fromRawAmount(stakingInfo.rewardRate.currency, "0");
  if (parsedAmountWrapped?.greaterThan("0")) {
    hypotheticalRewardRate = stakingInfo.getHypotheticalRewardRate(
      stakingInfo.stakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalStakedAmount.add(parsedAmountWrapped),
      stakingInfo.totalRewardRate
    );
  }

  // state for pending and submitted txn views
  const addTransaction = useTransactionAdder();
  const [attempting, setAttempting] = useState<boolean>(false);
  const [hash, setHash] = useState<string | undefined>();
  const wrappedOnDismiss = useCallback(() => {
    setHash(undefined);
    setAttempting(false);
    onDismiss();
  }, [onDismiss]);

  // pair contract for this token to be staked
  const dummyPair = new Pair(
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[0], "0"),
    CurrencyAmount.fromRawAmount(stakingInfo.tokens[1], "0")
  );
  const pairContract = usePairContract(dummyPair.liquidityToken.address);

  // approval data for stake
  const deadline = useTransactionDeadline();
  const router = useV1RouterContract();
  const { signatureData, gatherPermitSignature } = useV1LiquidityTokenPermit(
    parsedAmountWrapped,
    router?.address
  );
  const [approval, approveCallback] = useApproveCallback(
    parsedAmount,
    stakingInfo.stakingRewardAddress
  );

  const stakingContract = useStakingContract(stakingInfo.stakingRewardAddress);
  async function onStake() {
    setAttempting(true);
    if (stakingContract && parsedAmount && deadline) {
      if (approval === ApprovalState.APPROVED) {
        await stakingContract.stake(`0x${parsedAmount.quotient.toString(16)}`, {
          gasLimit: 350000
        });
      } else if (signatureData) {
        stakingContract
          .stakeWithPermit(
            `0x${parsedAmount.quotient.toString(16)}`,
            signatureData.deadline,
            signatureData.v,
            signatureData.r,
            signatureData.s,
            { gasLimit: 350000 }
          )
          .then((response: TransactionResponse) => {
            addTransaction(response, {
              type: TransactionType.DEPOSIT_LIQUIDITY_STAKING,
              token0Address: stakingInfo.tokens[0].address,
              token1Address: stakingInfo.tokens[1].address
            });
            setHash(response.hash);
          })
          .catch((error: any) => {
            setAttempting(false);
            console.log(error);
          });
      } else {
        setAttempting(false);
        throw new Error(
          "Attempting to stake without approval or a signature. Please contact support."
        );
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback((typedValue: string) => {
    setTypedValue(typedValue);
  }, []);

  // used for max input button
  const maxAmountInput = maxAmountSpend(userLiquidityUnstaked);
  const atMaxAmount = Boolean(
    maxAmountInput && parsedAmount?.equalTo(maxAmountInput)
  );
  const handleMax = useCallback(() => {
    maxAmountInput && onUserInput(maxAmountInput.toExact());
  }, [maxAmountInput, onUserInput]);

  async function onAttemptToApprove() {
    if (!pairContract || !provider || !deadline)
      throw new Error("missing dependencies");
    if (!parsedAmount) throw new Error("missing liquidity amount");

    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature();
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          await approveCallback();
        }
      }
    } else {
      await approveCallback();
    }
  }

  return (
    <Modal isOpen={isOpen} onDismiss={wrappedOnDismiss} maxHeight={90}>
      {!attempting && !hash && (
        <ContentWrapper gap="lg">
          <RowBetween>
            <ThemedText.DeprecatedMediumHeader>
              <Trans>Deposit</Trans>
            </ThemedText.DeprecatedMediumHeader>
            <CloseIcon onClick={wrappedOnDismiss} />
          </RowBetween>
          <CurrencyInputPanel
            value={typedValue}
            onUserInput={onUserInput}
            onMax={handleMax}
            showMaxButton={!atMaxAmount}
            currency={stakingInfo.stakedAmount.currency}
            pair={dummyPair}
            label={""}
            renderBalance={(amount) => (
              <Trans>
                Available to deposit: {formatCurrencyAmount(amount, 4)}
              </Trans>
            )}
            id="stake-liquidity-token"
          />

          <HypotheticalRewardRate
            dim={!hypotheticalRewardRate.greaterThan("0")}>
            <div>
              <ThemedText.DeprecatedBlack fontWeight={600}>
                <Trans>Weekly Rewards</Trans>
              </ThemedText.DeprecatedBlack>
            </div>

            <ThemedText.DeprecatedBlack>
              <Trans>
                {hypotheticalRewardRate
                  .multiply((60 * 60 * 24 * 7).toString())
                  .toSignificant(4, { groupSeparator: "," })}{" "}
                PANA / week
              </Trans>
            </ThemedText.DeprecatedBlack>
          </HypotheticalRewardRate>

          <RowBetween>
            <ButtonConfirmed
              mr="0.5rem"
              onClick={onAttemptToApprove}
              confirmed={
                approval === ApprovalState.APPROVED || signatureData !== null
              }
              disabled={
                approval !== ApprovalState.NOT_APPROVED ||
                signatureData !== null
              }>
              <Trans>Approve</Trans>
            </ButtonConfirmed>
            <ButtonError
              disabled={
                !!error ||
                (signatureData === null && approval !== ApprovalState.APPROVED)
              }
              error={!!error && !!parsedAmount}
              onClick={onStake}>
              {error ?? <Trans>Deposit</Trans>}
            </ButtonError>
          </RowBetween>
          <ProgressCircles
            steps={[
              approval === ApprovalState.APPROVED || signatureData !== null
            ]}
            disabled={true}
          />
        </ContentWrapper>
      )}
      {attempting && !hash && (
        <LoadingView onDismiss={wrappedOnDismiss}>
          <AutoColumn gap="12px" justify={"center"}>
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Depositing Liquidity</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>{parsedAmount?.toSignificant(4)} PANA-V1</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </LoadingView>
      )}
      {attempting && hash && (
        <SubmittedView onDismiss={wrappedOnDismiss} hash={hash}>
          <AutoColumn gap="12px" justify={"center"}>
            <ThemedText.DeprecatedLargeHeader>
              <Trans>Transaction Submitted</Trans>
            </ThemedText.DeprecatedLargeHeader>
            <ThemedText.DeprecatedBody fontSize={20}>
              <Trans>Deposited {parsedAmount?.toSignificant(4)} PANA-V1</Trans>
            </ThemedText.DeprecatedBody>
          </AutoColumn>
        </SubmittedView>
      )}
    </Modal>
  );
}
