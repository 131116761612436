import { useWeb3React } from "@web3-react/core";
import { sendAnalyticsEvent } from "components/AmplitudeAnalytics";
import { EventName } from "components/AmplitudeAnalytics/constants";
import { DEFAULT_TXN_DISMISS_MS, L2_TXN_DISMISS_MS } from "constants/misc";
import LibUpdater from "lib/hooks/transactions/updater";
import { useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { TransactionType } from "state/transactions/types";

import { L2_CHAIN_IDS } from "../../constants/chains";
import { useAddPopup } from "../application/hooks";
import { checkedTransaction, finalizeTransaction } from "./reducer";
import { SerializableTransactionReceipt } from "./types";

export default function Updater() {
  const { chainId } = useWeb3React();
  const addPopup = useAddPopup();
  // speed up popup dismisall time if on L2
  const isL2 = Boolean(chainId && L2_CHAIN_IDS.includes(chainId));
  const transactions = useAppSelector((state) => state.transactions);

  const dispatch = useAppDispatch();
  const onCheck = useCallback(
    ({
      chainId,
      hash,
      blockNumber
    }: {
      chainId: number;
      hash: string;
      blockNumber: number;
    }) => dispatch(checkedTransaction({ chainId, hash, blockNumber })),
    [dispatch]
  );
  const onReceipt = useCallback(
    ({
      chainId,
      hash,
      receipt
    }: {
      chainId: number;
      hash: string;
      receipt: SerializableTransactionReceipt;
    }) => {
      dispatch(
        finalizeTransaction({
          chainId,
          hash,
          receipt: {
            blockHash: receipt.blockHash,
            blockNumber: receipt.blockNumber,
            contractAddress: receipt.contractAddress,
            from: receipt.from,
            status: receipt.status,
            to: receipt.to,
            transactionHash: receipt.transactionHash,
            transactionIndex: receipt.transactionIndex
          }
        })
      );
      const tx = transactions[chainId]?.[hash];
      if (tx.info.type === TransactionType.SWAP) {
        sendAnalyticsEvent(EventName.SWAP_TRANSACTION_COMPLETED, {
          transaction_hash: tx.hash,
          succeeded: receipt.status === 1
        });
      }
      addPopup(
        {
          txn: { hash }
        },
        hash,
        isL2 ? L2_TXN_DISMISS_MS : DEFAULT_TXN_DISMISS_MS
      );
    },
    [addPopup, dispatch, isL2, transactions]
  );

  const pendingTransactions = useMemo(
    () => (chainId ? transactions[chainId] ?? {} : {}),
    [chainId, transactions]
  );

  return (
    <LibUpdater
      pendingTransactions={pendingTransactions}
      onCheck={onCheck}
      onReceipt={onReceipt}
    />
  );
}
