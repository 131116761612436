import { generateSymbol, parseFullSymbol, getData } from './helpers.js';
// import GetData from './helpers.js';
import { getTimeframe } from '../utils'
// import { subscribeOnStream, unsubscribeFromStream } from './streaming.js';

const configurationData = {
    // Represents the resolutions for bars supported by your datafeed
    supported_resolutions: ['1S','15S','30S','1','3','5','15','30','60','1D', '1W', '1M'],
    // The `exchanges` arguments are used for the `searchSymbols` method if a user selects the exchange
    exchanges: [
        // { value: "", name: "All Exchanges", desc: ""  },
        // { value: 'Kraken', name: 'Kraken', desc: 'Kraken bitcoin exchange'},
        // { value: 'Bitfinex', name: 'Bitfinex', desc: 'Bitfinex'},
        { value: 'Swap', name: 'Panaroma', desc: 'Panaroma' }
    ],
    // symbols_types: [
    //     { name: "All types", value: "" },
    //     { name: "Stock", value: "stock" },
    //     { name: "Index", value: "index" }
    // ],
    // The `symbols_types` arguments are used for the `searchSymbols` method if a user selects this symbol type
    symbols_types: [
        { name: 'crypto', value: 'crypto' }
    ]
};

const lastBarsCache = new Map();

// Obtains all symbols for all exchanges supported by CryptoCompare API
async function getAllSymbols() {
    // const data = await makeApiRequest('data/v3/all/exchanges');
    // console.log(data,"yuertrterytyt");
    const data = {
        "Swap": {
            "pairs": {
                "MATIC": [
                    "PANA",
                    "USDT",
                    "DAI",
                    "USDC",
                    "SHIB",
                    "BACL",
                    "WBTC",
                    "WETH",
                    "BAE"
                ],
                "PANA": [
                    "MATIC",
                    "USDT",
                    "DAI",
                    "USDC",
                    "WBTC",
                    "BAE",
                    "WETH",
                    "BACL",
                    "SHIB"
                ],
                "USDT": [
                    "PANA",
                    "MATIC",
                    "DAI",
                    "USDC"
                ],
                "DAI": [
                    "PANA",
                    "USDT",
                    "MATIC",
                    "USDC"
                ],
                "USDC": [
                    "PANA",
                    "USDT",
                    "MATIC",
                    "DAI",
                    "WBTC",
                    "WETH"
                ],
                "WBTC": [
                    "PANA",
                    "USDC",
                    "MATIC"
                ],
                "SHIB": [
                    "MATIC",
                    "PANA"
                ],
                "BAE": [
                    "PANA",
                    "MATIC"
                ],
                "WETH": [
                    "PANA",
                    "MATIC",
                    "USDC"
                ],
                "BACL": [
                    "MATIC",
                    "PANA"
                ]
            }
        }
    }
    let allSymbols = [];


    for (const exchange of configurationData.exchanges) {
        // const pairs = data.Data[exchange.value].pairs;
        const pairs = data[exchange.value].pairs;

        for (const leftPairPart of Object.keys(pairs)) {
            const symbols = pairs[leftPairPart].map(rightPairPart => {
                const symbol = generateSymbol(exchange.value, leftPairPart, rightPairPart);
                return {
                    symbol: symbol.short,
                    full_name: symbol.full,
                    description: symbol.short,
                    exchange: exchange.value,
                    type: 'crypto',
                };
            });
            allSymbols = [...allSymbols, ...symbols];
        }
    }
    return allSymbols;
}


export default {
    onReady: (callback) => {
        // console.log('[onReady]: Method call');
        setTimeout(() => callback(configurationData))
    },
    searchSymbols: async (
        userInput,
        exchange,
        symbolType,
        onResultReadyCallback
    ) => {
        // console.log('[searchSymbols]: Method call');
        const symbols = await getAllSymbols();
        const newSymbols = symbols.filter(symbol => {
            const isExchangeValid = exchange === '' || symbol.exchange === exchange;
            const isFullSymbolContainsInput = symbol.full_name
                .toLowerCase()
                .indexOf(userInput.toLowerCase()) !== -1;
            return isExchangeValid && isFullSymbolContainsInput;
        });
        // console.log('[searchSymbols]: Method call isFullSymbolContainsInput', newSymbols);
        onResultReadyCallback(newSymbols);
    },
    resolveSymbol: async (symbolName, onSymbolResolvedCallback, onResolveErrorCallback, extension) => {
        // console.log('[resolveSymbol]: Method call', symbolName);
        const symbols = await getAllSymbols();
        const symbolItem = symbols.find(({ full_name }) => full_name === symbolName);
        if (!symbolItem) {
            // console.log('[resolveSymbol]: Cannot resolve symbol', symbolName);
            onResolveErrorCallback('Cannot resolve symbol');
            return;
        }
        // Symbol information object
        const symbolInfo = {
            ticker: symbolItem.full_name,
            name: symbolItem.symbol,
            description: symbolItem.description,
            type: symbolItem.type,
            session: '24x7',
            timezone: 'Asia/Dubai',
            exchange: symbolItem.exchange,
            minmov: 1,
            pricescale: 10000,
            has_intraday: true,
            has_seconds: true,
            visible_plots_set: 'ohlc',
            has_weekly_and_monthly: false,
            supported_resolutions: configurationData.supported_resolutions,
            volume_precision: 2,
            data_status: 'streaming',
        };
        // console.log('[resolveSymbol]: Symbol resolved', symbolName);
        onSymbolResolvedCallback(symbolInfo);
    },
    getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
        const { from, to, firstDataRequest } = periodParams;
        // console.log('[getBars]: Method call', symbolInfo, resolution, from, to);
        const parsedSymbol = parseFullSymbol(symbolInfo.full_name);
        const urlParameters = {
            e: parsedSymbol.exchange,
            fsym: parsedSymbol.fromSymbol,
            tsym: parsedSymbol.toSymbol,
            toTs: to,
            limit: 2000,
        };
        const query = Object.keys(urlParameters)
            .map(name => `${name}=${encodeURIComponent(urlParameters[name])}`)
            .join('&');
        // console.log('[getBars]: Method call parsedSymbol', parsedSymbol, query);
        try {
            // const data = await makeApiRequest(`data/histoday?${query}`);
            const timeFrame = getTimeframe(resolution);
            
            const data = await getData(from,to, resolution, symbolInfo.name)
            if (data && data.length < 1) {
                // "noData" should be set if there is no data in the requested period
                onHistoryCallback([], { noData: true });
                return;
            }
            let bars = [];

            data && data.forEach(bar => {
                // console.log(bar.time >= from && bar.time < to, from, to, bar.time, "bar, bar.low");
                if (bar.time >= from && bar.time < to) {
                    bars = [...bars, {
                        time: bar.time * 1000,
                        low: bar.low,
                        high: bar.high,
                        open: bar.open,
                        close: bar.close,
                    }];
                }
            });

            if (firstDataRequest) {
                lastBarsCache.set(symbolInfo.full_name, { ...bars[bars.length - 1] });
            }

            // console.log(`[getBars]: returned ${bars.length} bar(s)`, bars);
            onHistoryCallback(bars, { noData: false });
            // console.log(`[getBars]: returned 2323 ${bars.length} bar(s)`, bars);

        } catch (error) {
            console.log('[getBars]: Get error', error);
            onErrorCallback(error);
        }
    },
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscriberUID, onResetCacheNeededCallback) => {
        // console.log('[subscribeBars]: Method call with subscriberUID:', subscriberUID);
        // subscribeOnStream(
        //     symbolInfo,
        //     resolution,
        //     onRealtimeCallback,
        //     subscriberUID,
        //     onResetCacheNeededCallback,
        //     lastBarsCache.get(symbolInfo.full_name)
        // );
    },
    unsubscribeBars: (subscriberUID) => {
        // console.log('[unsubscribeBars]: Method call with subscriberUID:', subscriberUID);
        // unsubscribeFromStream(subscriberUID);
    },
};