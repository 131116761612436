import "@reach/dialog/styles.css";
import "inter-ui";
import "polyfills";
import "components/analytics";

import { FeatureFlagsProvider } from "featureFlag";
import { BlockNumberProvider } from "lib/hooks/useBlockNumber";
import { MulticallUpdater } from "lib/state/multicall";
import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";

import Blocklist from "./components/Blocklist";
import Web3Provider from "./components/Web3Provider";
import { LanguageProvider } from "./i18n";
import App from "./pages/App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./state";
import ApplicationUpdater from "./state/application/updater";
import ListsUpdater from "./state/lists/updater";
import LogsUpdater from "./state/logs/updater";
import TransactionUpdater from "./state/transactions/updater";
import UserUpdater from "./state/user/updater";
import ThemeProvider, { ThemedGlobalStyle } from "./theme";
import RadialGradientByChainUpdater from "./theme/RadialGradientByChainUpdater";
import TokenDataContextProvider, { Updater as TokenDataContextUpdater } from './contexts/TokenData'
import ApplicationContextProvider from './contexts/Application'
import LocalStorageContextProvider, { Updater as LocalStorageContextUpdater } from './contexts/LocalStorage'
import GlobalDataContextProvider from './contexts/GlobalData'
import PairDataContextProvider, { Updater as PairDataContextUpdater } from './contexts/PairData'
import UserContextProvider from './contexts/User'


if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

function ContextProviders({ children } : any) {
  return (
    <LocalStorageContextProvider>
      <ApplicationContextProvider>
        <TokenDataContextProvider>
          <GlobalDataContextProvider>
            <PairDataContextProvider>
              <UserContextProvider>{children}</UserContextProvider>
            </PairDataContextProvider>
          </GlobalDataContextProvider>
        </TokenDataContextProvider>
      </ApplicationContextProvider>
    </LocalStorageContextProvider>
  )
}

function Updaters() {
  return (
    <>
      <RadialGradientByChainUpdater />
      <ListsUpdater />
      <UserUpdater />
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <LogsUpdater />
      <TokenDataContextUpdater />
      <PairDataContextUpdater />
      <LocalStorageContextUpdater />
    </>
  );
}

const container = document.getElementById("root") as HTMLElement;

createRoot(container).render(
  <StrictMode>
  <ContextProviders>

    <Provider store={store}>
      <FeatureFlagsProvider>
        <HashRouter>
          <LanguageProvider>
            <Web3Provider>
              <Blocklist>
                <BlockNumberProvider>
                  <Updaters />
                  <ThemeProvider>
                    <ThemedGlobalStyle />
                    <App />
                  </ThemeProvider>
                </BlockNumberProvider>
              </Blocklist>
            </Web3Provider>
          </LanguageProvider>
        </HashRouter>
      </FeatureFlagsProvider>
    </Provider>
  </ContextProviders>

  </StrictMode>
);

if (process.env.REACT_APP_SERVICE_WORKER !== "false") {
  serviceWorkerRegistration.register();
}
