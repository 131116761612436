import { Interface } from "@ethersproject/abi";
import { Trans } from "@lingui/macro";
import STAKING_REWARDS_ABI from "@panaromafinance/panaromaswap_liquiditystaker/build/StakingRewards.json";
import { CurrencyAmount, Token } from "@panaromafinance/panaromaswap_sdkcore";
import { Pair } from "@panaromafinance/panaromaswap_v1sdk";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "constants/chains";
import useCurrentBlockTimestamp from "hooks/useCurrentBlockTimestamp";
import JSBI from "jsbi";
import {
  NEVER_RELOAD,
  useMultipleContractSingleData
} from "lib/hooks/multicall";
import tryParseCurrencyAmount from "lib/utils/tryParseCurrencyAmount";
import { ReactNode, useMemo } from "react";

import {
  DAI,
  PANA,
  USDC_MAINNET,
  USDT,
  WBTC,
  WRAPPED_NATIVE_CURRENCY
} from "../../constants/tokens";

const { abi } = STAKING_REWARDS_ABI

const STAKING_REWARDS_INTERFACE = new Interface(abi as any);

export const STAKING_GENESIS = 1600387200;

export const REWARDS_DURATION_DAYS = 60;

export const STAKING_REWARDS_INFO: {
  [chainId: number]: {
    tokens: [Token, Token];
    stakingRewardAddress: string;
  }[];
} = {
  1: [
    {
      tokens: [WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token, DAI],
      stakingRewardAddress: "0xa1484C3aa22a66C62b77E0AE78E15258bd0cB711"
    },
    {
      tokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
        USDC_MAINNET
      ],
      stakingRewardAddress: "0x7FBa4B8Dc5E7616e59622806932DBea72537A56b"
    },
    {
      tokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
        USDT
      ],
      stakingRewardAddress: "0x6C3e4cb2E96B01F4b866965A91ed4437839A121a"
    },
    {
      tokens: [
        WRAPPED_NATIVE_CURRENCY[SupportedChainId.MAINNET] as Token,
        WBTC
      ],
      stakingRewardAddress: "0xCA35e32e7926b96A9988f61d510E038108d8068e"
    }
  ]
};

export interface StakingInfo {
  // the address of the reward contract
  stakingRewardAddress: string;
  // the tokens involved in this pair
  tokens: [Token, Token];
  // the amount of token currently staked, or undefined if no account
  stakedAmount: CurrencyAmount<Token>;
  // the amount of reward token earned by the active account, or undefined if no account
  earnedAmount: CurrencyAmount<Token>;
  // the total amount of token staked in the contract
  totalStakedAmount: CurrencyAmount<Token>;
  // the amount of token distributed per second to all LPs, constant
  totalRewardRate: CurrencyAmount<Token>;
  // the current amount of token distributed to the active account per second.
  // equivalent to percent of total supply * reward rate
  rewardRate: CurrencyAmount<Token>;
  // when the period ends
  periodFinish: Date | undefined;
  // if pool is active
  active: boolean;
  // calculates a hypothetical amount of token distributed to the active account per second.
  getHypotheticalRewardRate: (
    stakedAmount: CurrencyAmount<Token>,
    totalStakedAmount: CurrencyAmount<Token>,
    totalRewardRate: CurrencyAmount<Token>
  ) => CurrencyAmount<Token>;
}

// gets the staking info from the network for the active chain id
export function useStakingInfo(pairToFilterBy?: Pair | null): StakingInfo[] {
  const { chainId, account } = useWeb3React();

  // detect if staking is ended
  const currentBlockTimestamp = useCurrentBlockTimestamp();

  const info = useMemo(
    () =>
      chainId
        ? STAKING_REWARDS_INFO[chainId]?.filter((stakingRewardInfo) =>
            pairToFilterBy === undefined
              ? true
              : pairToFilterBy === null
              ? false
              : pairToFilterBy.involvesToken(stakingRewardInfo.tokens[0]) &&
                pairToFilterBy.involvesToken(stakingRewardInfo.tokens[1])
          ) ?? []
        : [],
    [chainId, pairToFilterBy]
  );

  const pana = chainId ? PANA[chainId] : undefined;

  const rewardsAddresses = useMemo(
    () => info.map(({ stakingRewardAddress }) => stakingRewardAddress),
    [info]
  );

  const accountArg = useMemo(() => [account ?? undefined], [account]);

  // get all the info from the staking rewards contracts
  const balances = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "balanceOf",
    accountArg
  );
  const earnedAmounts = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "earned",
    accountArg
  );
  const totalSupplies = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "totalSupply"
  );

  // tokens per second, constants
  const rewardRates = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "rewardRate",
    undefined,
    NEVER_RELOAD
  );
  const periodFinishes = useMultipleContractSingleData(
    rewardsAddresses,
    STAKING_REWARDS_INTERFACE,
    "periodFinish",
    undefined,
    NEVER_RELOAD
  );

  return useMemo(() => {
    if (!chainId || !pana) return [];

    return rewardsAddresses.reduce<StakingInfo[]>(
      (memo, rewardsAddress, index) => {
        // these two are dependent on account
        const balanceState = balances[index];
        const earnedAmountState = earnedAmounts[index];

        // these get fetched regardless of account
        const totalSupplyState = totalSupplies[index];
        const rewardRateState = rewardRates[index];
        const periodFinishState = periodFinishes[index];

        if (
          // these may be undefined if not logged in
          !balanceState?.loading &&
          !earnedAmountState?.loading &&
          // always need these
          totalSupplyState &&
          !totalSupplyState.loading &&
          rewardRateState &&
          !rewardRateState.loading &&
          periodFinishState &&
          !periodFinishState.loading
        ) {
          if (
            balanceState?.error ||
            earnedAmountState?.error ||
            totalSupplyState.error ||
            rewardRateState.error ||
            periodFinishState.error
          ) {
            console.error("Failed to load staking rewards info");
            return memo;
          }

          // get the LP token
          const tokens = info[index].tokens;
          const dummyPair = new Pair(
            CurrencyAmount.fromRawAmount(tokens[0], "0"),
            CurrencyAmount.fromRawAmount(tokens[1], "0")
          );

          // check for account, if no account set to 0

          const stakedAmount = CurrencyAmount.fromRawAmount(
            dummyPair.liquidityToken,
            JSBI.BigInt(balanceState?.result?.[0] ?? 0)
          );
          const totalStakedAmount = CurrencyAmount.fromRawAmount(
            dummyPair.liquidityToken,
            JSBI.BigInt(totalSupplyState.result?.[0])
          );
          const totalRewardRate = CurrencyAmount.fromRawAmount(
            pana,
            JSBI.BigInt(rewardRateState.result?.[0])
          );

          const getHypotheticalRewardRate = (
            stakedAmount: CurrencyAmount<Token>,
            totalStakedAmount: CurrencyAmount<Token>,
            totalRewardRate: CurrencyAmount<Token>
          ): CurrencyAmount<Token> => {
            return CurrencyAmount.fromRawAmount(
              pana,
              JSBI.greaterThan(totalStakedAmount.quotient, JSBI.BigInt(0))
                ? JSBI.divide(
                    JSBI.multiply(
                      totalRewardRate.quotient,
                      stakedAmount.quotient
                    ),
                    totalStakedAmount.quotient
                  )
                : JSBI.BigInt(0)
            );
          };

          const individualRewardRate = getHypotheticalRewardRate(
            stakedAmount,
            totalStakedAmount,
            totalRewardRate
          );

          const periodFinishSeconds = periodFinishState.result?.[0]?.toNumber();
          const periodFinishMs = periodFinishSeconds * 1000;

          // compare period end timestamp vs current block timestamp (in seconds)
          const active =
            periodFinishSeconds && currentBlockTimestamp
              ? periodFinishSeconds > currentBlockTimestamp.toNumber()
              : true;

          memo.push({
            stakingRewardAddress: rewardsAddress,
            tokens: info[index].tokens,
            periodFinish:
              periodFinishMs > 0 ? new Date(periodFinishMs) : undefined,
            earnedAmount: CurrencyAmount.fromRawAmount(
              pana,
              JSBI.BigInt(earnedAmountState?.result?.[0] ?? 0)
            ),
            rewardRate: individualRewardRate,
            totalRewardRate,
            stakedAmount,
            totalStakedAmount,
            getHypotheticalRewardRate,
            active
          });
        }
        return memo;
      },
      []
    );
  }, [
    balances,
    chainId,
    currentBlockTimestamp,
    earnedAmounts,
    info,
    periodFinishes,
    rewardRates,
    rewardsAddresses,
    totalSupplies,
    pana
  ]);
}

export function useTotalPanaromaEarned(): CurrencyAmount<Token> | undefined {
  const { chainId } = useWeb3React();
  const pana = chainId ? PANA[chainId] : undefined;
  const stakingInfos = useStakingInfo();

  return useMemo(() => {
    if (!pana) return undefined;
    return (
      stakingInfos?.reduce(
        (accumulator, stakingInfo) => accumulator.add(stakingInfo.earnedAmount),
        CurrencyAmount.fromRawAmount(pana, "0")
      ) ?? CurrencyAmount.fromRawAmount(pana, "0")
    );
  }, [stakingInfos, pana]);
}

// based on typed value
export function useDerivedStakeInfo(
  typedValue: string,
  stakingToken: Token | undefined,
  userLiquidityUnstaked: CurrencyAmount<Token> | undefined
): {
  parsedAmount?: CurrencyAmount<Token>;
  error?: ReactNode;
} {
  const { account } = useWeb3React();

  const parsedInput: CurrencyAmount<Token> | undefined = tryParseCurrencyAmount(
    typedValue,
    stakingToken
  );

  const parsedAmount =
    parsedInput &&
    userLiquidityUnstaked &&
    JSBI.lessThanOrEqual(parsedInput.quotient, userLiquidityUnstaked.quotient)
      ? parsedInput
      : undefined;

  let error: ReactNode | undefined;
  if (!account) {
    error = <Trans>Connect Wallet</Trans>;
  }
  if (!parsedAmount) {
    error = error ?? <Trans>Enter an amount</Trans>;
  }

  return {
    parsedAmount,
    error
  };
}
