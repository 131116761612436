import React, { createContext, useContext, useReducer, useMemo, useCallback, useEffect, useState } from 'react'
import { useAllPairData } from './PairData'
import { stakingClient, useUpdateClient } from '../apollo/client'
import {
  USER_TRANSACTIONS,
  USER_POSITIONS,
  USER_HISTORY,
  PAIR_DAY_DATA_BULK,
  MINING_POSITIONS,
} from '../apollo/queries'
import { useTimeframe } from './Application'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEthPrice } from './GlobalData'
import { getLPReturnsOnPair } from '../utils/returns'
import { timeframeOptions } from '../constants'
import { useNetworkSelection } from './LocalStorage'
import Web3 from "web3";
import { SupportedChainId } from "constants/chains";
import lockFactoryABI from "../abis/lockFactoryBalanceABI.json"
import lockTokenABA from "../abis/lockTokenABA.json"
import { useAppSelector } from "state/hooks";
import { RPC_URLS } from "constants/networks";

dayjs.extend(utc)

const UPDATE_TRANSACTIONS = 'UPDATE_TRANSACTIONS'
const UPDATE_POSITIONS = 'UPDATE_POSITIONS '
const UPDATE_MINING_POSITIONS = 'UPDATE_MINING_POSITIONS'
const UPDATE_USER_POSITION_HISTORY = 'UPDATE_USER_POSITION_HISTORY'
const UPDATE_USER_PAIR_RETURNS = 'UPDATE_USER_PAIR_RETURNS'

const TRANSACTIONS_KEY = 'TRANSACTIONS_KEY'
const POSITIONS_KEY = 'POSITIONS_KEY'
const MINING_POSITIONS_KEY = 'MINING_POSITIONS_KEY'
const USER_SNAPSHOTS = 'USER_SNAPSHOTS'
const USER_PAIR_RETURNS_KEY = 'USER_PAIR_RETURNS_KEY'

const UPDATE_LOCKED_POSITIONS = 'UPDATE_LOCKED_POSITIONS'
const UPDATE_POSITION_LOCKS = 'UPDATE_POSITION_LOCKS'

const UserContext = createContext()

function useUserContext() {
  return useContext(UserContext)
}

function reducer(state, { type, payload }) {
  switch (type) {
    case UPDATE_TRANSACTIONS: {
      const { account, transactions } = payload
      return {
        ...state,
        [account]: {
          ...state?.[account],
          [TRANSACTIONS_KEY]: transactions,
        },
      }
    }
    case UPDATE_POSITIONS: {
      const { account, positions } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [POSITIONS_KEY]: positions },
      }
    }
    case UPDATE_MINING_POSITIONS: {
      const { account, miningPositions } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [MINING_POSITIONS_KEY]: miningPositions },
      }
    }
    case UPDATE_USER_POSITION_HISTORY: {
      const { account, historyData } = payload
      return {
        ...state,
        [account]: { ...state?.[account], [USER_SNAPSHOTS]: historyData },
      }
    }

    case UPDATE_LOCKED_POSITIONS: {
      const { positions } = payload
      return {
        ...state,
        positions
      }
    }

    case UPDATE_POSITION_LOCKS: {
      const { locks } = payload
      return {
        ...state,
        locks
      }
    }

    case UPDATE_USER_PAIR_RETURNS: {
      const { account, pairAddress, data } = payload
      return {
        ...state,
        [account]: {
          ...state?.[account],
          [USER_PAIR_RETURNS_KEY]: {
            ...state?.[account]?.[USER_PAIR_RETURNS_KEY],
            [pairAddress]: data,
          },
        },
      }
    }

    default: {
      throw Error(`Unexpected action type in DataContext reducer: '${type}'.`)
    }
  }
}

const INITIAL_STATE = {}

export default function Provider({ children }) {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)

  const updateTransactions = useCallback((account, transactions) => {
    dispatch({
      type: UPDATE_TRANSACTIONS,
      payload: {
        account,
        transactions,
      },
    })
  }, [])

  const updatePositions = useCallback((account, positions) => {
    dispatch({
      type: UPDATE_POSITIONS,
      payload: {
        account,
        positions,
      },
    })
  }, [])

  const updateMiningPositions = useCallback((account, miningPositions) => {
    dispatch({
      type: UPDATE_MINING_POSITIONS,
      payload: {
        account,
        miningPositions,
      },
    })
  }, [])

  const updateUserSnapshots = useCallback((account, historyData) => {
    dispatch({
      type: UPDATE_USER_POSITION_HISTORY,
      payload: {
        account,
        historyData,
      },
    })
  }, [])

  const updateUserPairReturns = useCallback((account, pairAddress, data) => {
    dispatch({
      type: UPDATE_USER_PAIR_RETURNS,
      payload: {
        account,
        pairAddress,
        data,
      },
    })
  }, [])

  const updateLockedPositions = useCallback((positions) => {
    dispatch({
      type: UPDATE_LOCKED_POSITIONS,
      payload: {
        positions
      },
    })
  }, [])

  const updatePositionLocks = useCallback((locks) => {
    dispatch({
      type: UPDATE_POSITION_LOCKS,
      payload: {
        locks
      },
    })
  }, [])

  return (
    <UserContext.Provider
      value={useMemo(
        () => [
          state,
          { updateTransactions, updatePositions, updateMiningPositions, updateUserSnapshots, updateUserPairReturns, updateLockedPositions, updatePositionLocks },
        ],
        [state, updateTransactions, updatePositions, updateMiningPositions, updateUserSnapshots, updateUserPairReturns, updateLockedPositions, updatePositionLocks]
      )}
    >
      {children}
    </UserContext.Provider>
  )
}

export function useUserTransactions(account) {
  const [state, { updateTransactions }] = useUserContext()
  const transactions = state?.[account]?.[TRANSACTIONS_KEY]

  const [network] = useNetworkSelection()
  const dataClient = useUpdateClient(network)

  useEffect(() => {
    async function fetchData(account) {
      try {
        let result = await dataClient.query({
          query: USER_TRANSACTIONS,
          variables: {
            user: account,
          },
          fetchPolicy: 'no-cache',
        })
        if (result?.data) {
          updateTransactions(account, result?.data)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (account) {
      fetchData(account)
    }
  }, [account, network, updateTransactions])

  return transactions || {}
}

/**
 * Store all the snapshots of liquidity activity for this account.
 * Each snapshot is a moment when an LP position was created or updated.
 * @param {*} account
 */
export function useUserSnapshots(account, chainId) {
  const [state, { updateUserSnapshots }] = useUserContext()
  const snapshots = state?.[account]?.[USER_SNAPSHOTS]


  const dataClient = useUpdateClient(chainId)
  
  useEffect(() => {
    async function fetchData() {
      try {
        let skip1 = 0
        let allResults = []
        let found = false
        while (!found) {
          let result = await dataClient.query({
            query: USER_HISTORY,
            variables: {
              skip: skip1,
              user: account,
            },
            fetchPolicy: 'no-cache',
          })
          allResults = allResults.concat(result.data.liquidityPositionSnapshots)
          if (result.data.liquidityPositionSnapshots.length < 1000) {
            found = true
          } else {
            skip1 += 1000
          }
        }
        if (allResults) {
          updateUserSnapshots(account, allResults)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!snapshots && account) {
      fetchData()
    }
  }, [account, updateUserSnapshots])

  return snapshots
}

/**
 * For a given position (data about holding) and user, get the chart
 * data for the fees and liquidity over time
 * @param {*} position
 * @param {*} account
 */
// export function useUserPositionChart(position, account) {
//   const pairAddress = position?.pair?.id
//   const [state, { updateUserPairReturns }] = useUserContext()

//   // get oldest date of data to fetch
//   const startDateTimestamp = useStartTimestamp()

//   // get users adds and removes on this pair
//   const snapshots = useUserSnapshots(account)
//   const pairSnapshots =
//     snapshots &&
//     position &&
//     snapshots.filter((currentSnapshot) => {
//       return currentSnapshot.pair.id === position.pair.id
//     })

//   // get data needed for calculations
//   const currentPairData = usePairData(pairAddress)
//   const [currentETHPrice] = useEthPrice()

//   // formatetd array to return for chart data
//   const formattedHistory = state?.[account]?.[USER_PAIR_RETURNS_KEY]?.[pairAddress]

//   useEffect(() => {
//     //     async function fetchData() {
//     //       let fetchedData = await getHistoricalPairReturns(
//     //         startDateTimestamp,
//     //         currentPairData,
//     //         pairSnapshots,
//     //         currentETHPrice
//     //       )
//     //       updateUserPairReturns(account, pairAddress, fetchedData)
//     //     }
//     //     if (
//     //       account &&
//     //       startDateTimestamp &&
//     //       pairSnapshots &&
//     //       !formattedHistory &&
//     //       currentPairData &&
//     //       Object.keys(currentPairData).length > 0 &&
//     //       pairAddress &&
//     //       currentETHPrice
//     //     ) {
//     //       fetchData()
//     //     }
//     //   }, [
//     //     account,
//     //     startDateTimestamp,
//     //     pairSnapshots,
//     //     formattedHistory,
//     //     pairAddress,
//     //     currentPairData,
//     //     currentETHPrice,
//     //     updateUserPairReturns,
//     //     position.pair.id,
//     //   ])
//   })

//   return formattedHistory
// }

/**
 * For each day starting with min(first position timestamp, beginning of time window),
 * get total liquidity supplied by user in USD. Format in array with date timestamps
 * and usd liquidity value.
 */
export function useUserLiquidityChart(account) {
  const history = useUserSnapshots(account)
  // formatetd array to return for chart data
  const [formattedHistory, setFormattedHistory] = useState()

  const [startDateTimestamp, setStartDateTimestamp] = useState()
  const [activeWindow] = useTimeframe()

  const [network] = useNetworkSelection()
  const dataClient = useUpdateClient(network)

  // monitor the old date fetched
  useEffect(() => {
    const utcEndTime = dayjs.utc()
    // based on window, get starttime
    let utcStartTime
    switch (activeWindow) {
      case timeframeOptions.WEEK:
        utcStartTime = utcEndTime.subtract(1, 'week').startOf('day')
        break
      case timeframeOptions.ALL_TIME:
        utcStartTime = utcEndTime.subtract(1, 'year')
        break
      default:
        utcStartTime = utcEndTime.subtract(1, 'year').startOf('year')
        break
    }
    let startTime = utcStartTime.unix() - 1
    if ((activeWindow && startTime < startDateTimestamp) || !startDateTimestamp) {
      setStartDateTimestamp(startTime)
    }
  }, [activeWindow, startDateTimestamp, network])

  useEffect(() => {
    async function fetchData() {
      let dayIndex = parseInt(startDateTimestamp / 86400) // get unique day bucket unix
      const currentDayIndex = parseInt(dayjs.utc().unix() / 86400)

      // sort snapshots in order
      let sortedPositions = history.sort((a, b) => {
        return parseInt(a.timestamp) > parseInt(b.timestamp) ? 1 : -1
      })
      // if UI start time is > first position time - bump start index to this time
      if (parseInt(sortedPositions[0].timestamp) > dayIndex) {
        dayIndex = parseInt(parseInt(sortedPositions[0].timestamp) / 86400)
      }

      const dayTimestamps = []
      // get date timestamps for all days in view
      while (dayIndex < currentDayIndex) {
        dayTimestamps.push(parseInt(dayIndex) * 86400)
        dayIndex = dayIndex + 1
      }

      const pairs = history.reduce((pairList, position) => {
        return [...pairList, position.pair.id]
      }, [])

      // get all day datas where date is in this list, and pair is in pair list
      let {
        data: { pairDayDatas },
      } = await dataClient.query({
        query: PAIR_DAY_DATA_BULK(pairs, startDateTimestamp),
      })

      const formattedHistory = []

      // map of current pair => ownership %
      const ownershipPerPair = {}
      for (const index in dayTimestamps) {
        const dayTimestamp = dayTimestamps[index]
        const timestampCeiling = dayTimestamp + 86400

        // cycle through relevant positions and update ownership for any that we need to
        const relevantPositions = history.filter((snapshot) => {
          return snapshot.timestamp < timestampCeiling && snapshot.timestamp > dayTimestamp
        })
        for (const index in relevantPositions) {
          const position = relevantPositions[index]
          // case where pair not added yet
          if (!ownershipPerPair[position.pair.id]) {
            ownershipPerPair[position.pair.id] = {
              lpTokenBalance: position.liquidityTokenBalance,
              timestamp: position.timestamp,
            }
          }
          // case where more recent timestamp is found for pair
          if (ownershipPerPair[position.pair.id] && ownershipPerPair[position.pair.id].timestamp < position.timestamp) {
            ownershipPerPair[position.pair.id] = {
              lpTokenBalance: position.liquidityTokenBalance,
              timestamp: position.timestamp,
            }
          }
        }

        const relavantDayDatas = Object.keys(ownershipPerPair).map((pairAddress) => {
          // find last day data after timestamp update
          const dayDatasForThisPair = pairDayDatas.filter((dayData) => {
            return dayData.pairAddress === pairAddress
          })
          // find the most recent reference to pair liquidity data
          let mostRecent = dayDatasForThisPair[0]
          for (const index in dayDatasForThisPair) {
            const dayData = dayDatasForThisPair[index]
            if (dayData.date < dayTimestamp && dayData.date > mostRecent.date) {
              mostRecent = dayData
            }
          }
          return mostRecent
        })

        // now cycle through pair day datas, for each one find usd value = ownership[address] * reserveUSD
        const dailyUSD = relavantDayDatas.reduce((totalUSD, dayData) => {
          if (dayData) {
            return (totalUSD =
              totalUSD +
              (ownershipPerPair[dayData.pairAddress]
                ? (parseFloat(ownershipPerPair[dayData.pairAddress].lpTokenBalance) / parseFloat(dayData.totalSupply)) *
                parseFloat(dayData.reserveUSD)
                : 0))
          } else {
            return totalUSD
          }
        }, 0)

        formattedHistory.push({
          date: dayTimestamp,
          valueUSD: dailyUSD,
        })
      }

      setFormattedHistory(formattedHistory)
    }
    if (history && startDateTimestamp && history.length > 0) {
      fetchData()
    }
  }, [history, startDateTimestamp, network])

  return formattedHistory
}

export function useUserPositions(account, chainId) {
  const [state, { updatePositions }] = useUserContext()
  const positions = state?.[account]?.[POSITIONS_KEY]

  const snapshots = useUserSnapshots(account, chainId)
  const [ethPrice] = useEthPrice()

  const dataClient = useUpdateClient(chainId)

  useEffect(() => {
    async function fetchData(account) {
      try {
        let result = await dataClient.query({
          query: USER_POSITIONS,
          variables: {
            user: account,
          },
          fetchPolicy: 'no-cache',
        })
        if (result?.data?.liquidityPositions) {
          let formattedPositions = await Promise.all(
            result?.data?.liquidityPositions.map(async (positionData) => {
              const returnData = await getLPReturnsOnPair(account, positionData.pair, ethPrice, snapshots, dataClient)
              return {
                ...positionData,
                ...returnData,
              }
            })
          )
          updatePositions(account, formattedPositions)
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (account && ethPrice && snapshots) {
      fetchData(account)
    }
  }, [account, updatePositions, ethPrice, snapshots])

  return positions
}

export function useMiningPositions(account) {
  const [state, { updateMiningPositions }] = useUserContext()
  const allPairData = useAllPairData()
  const miningPositions = state?.[account]?.[MINING_POSITIONS_KEY]

  const snapshots = useUserSnapshots(account)

  useEffect(() => {
    async function fetchData(account) {
      try {
        let miningPositionData = []
        let result = await stakingClient.query({
          query: MINING_POSITIONS(account),
          fetchPolicy: 'no-cache',
        })
        if (!result?.data?.user?.miningPosition) {
          return
        }
        miningPositionData = result.data.user.miningPosition
        for (const miningPosition of miningPositionData) {
          const pairAddress = miningPosition.miningPool.pair.id
          miningPosition.pairData = allPairData[pairAddress]
        }
        updateMiningPositions(account, miningPositionData)
      } catch (e) {
        console.log(e)
      }
    }

    if (account && snapshots) {
      fetchData(account)
    }
  }, [account, miningPositions, updateMiningPositions, snapshots, allPairData])
  return miningPositions
}

let web3 = new Web3(
  new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
);

const listOfPoolsForLock = ["SWAP", "EXTICO"];


export function useLockedPositions(account, position, chainId) {
  const [state, { updateLockedPositions, updatePositionLocks }] = useUserContext()
  const positions = state?.positions

  const rpcurlQuickNode = useAppSelector((state) => state.user.rpcUrl)
  const rpcurl = rpcurlQuickNode ? rpcurlQuickNode : RPC_URLS;

  const locks = state?.locks

  const [ethPrice] = useEthPrice()

  const dataClient = useUpdateClient(chainId)

  let arr = []
  let count = 0
  let count1 = 0

  useEffect(() => {
    async function fetchData(account) {

        const snapshots = await dataClient.query({
          query: USER_HISTORY,
          variables: {
            skip: 0,
            user: account.toLowerCase(),
          },
          fetchPolicy: 'no-cache',
        })
        
        let allResults = snapshots.data.liquidityPositionSnapshots
        if (allResults) {
          try {
            let result = await dataClient.query({
              query: USER_POSITIONS,
              variables: {
                user: account.toLowerCase(),
              },
              fetchPolicy: 'no-cache',
            })
            if (result?.data?.liquidityPositions) {
              let formattedPositions = await Promise.all(
                result?.data?.liquidityPositions.map(async (positionData) => {
                  const returnData = await getLPReturnsOnPair(account.toLowerCase(), positionData.pair, ethPrice, allResults, dataClient)
                  return {
                    ...positionData,
                    ...returnData,
                  }
                })
              )
  
              arr.push(formattedPositions)
              count++;
              if (Array.isArray(position)) {
                if (position.length> 1&& count === ((position.length * listOfPoolsForLock.length) - count1)) {
                  // console.log(count,position.length,(position.length * listOfPoolsForLock.length) - count1, "1 formattedPositions xxpp");
                  updateLockedPositions(arr)
                } else if (position.length === 1) {
                  updateLockedPositions(arr)
                }
              } else if(count === (listOfPoolsForLock.length - count1)){
                updateLockedPositions(arr)
              }
  
            }
          } catch (e) {
            console.log(e)
          }
        }
    }
    if (account && ethPrice && position) {

      switch (chainId) {
        case SupportedChainId.ARBITRUM_ONE:
    
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.ARBITRUM_ONE])
          );
          break;
        case SupportedChainId.ARBITRUM_GOERLI:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.ARBITRUM_GOERLI])
          );
          break;
        case SupportedChainId.GOERLI:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.GOERLI])
          );
          break;
        case SupportedChainId.MAINNET:
          // console.log("11111 RPC_URLS['1']", RPC_URLS["1"]);
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.MAINNET])
          );
          break;
        case SupportedChainId.OPTIMISM:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.OPTIMISM])
          );
          break;
        case SupportedChainId.OPTIMISTIC_GOERLI:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.OPTIMISTIC_GOERLI])
          );
          break;
        case SupportedChainId.POLYGON:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.POLYGON])
          );
          break;
        case SupportedChainId.POLYGON_MUMBAI:
          web3 = new Web3(
            new Web3.providers.HttpProvider(rpcurl[SupportedChainId.POLYGON_MUMBAI])
          );
          break;
        default:
          web3 = new Web3(
            new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
          );
      }

      if (Array.isArray(position)) {
        let arr = []
        position.map(async p => {
          const tokenContract = p.pair.id;
          let noOfLockIndex = 1;

          arr.push({
            "pair": tokenContract,
            "token0": p.pair.token0.symbol,
            "token1": p.pair.token1.symbol,
            "reserveUSD": p.pair.reserveUSD,
            "data": []
          })

          listOfPoolsForLock.map(async x => {

            const contractAddress = x == listOfPoolsForLock[0] ? process.env['REACT_APP_LOCK_FACTORY_ADDRESS'] : process.env['REACT_APP_LOCK_FACTORY_ADDRESS_EXTICO'];

            if (contractAddress != "0x0000000000000000000000000000000000000000") {
              const contract = new web3.eth.Contract(lockFactoryABI, contractAddress);
              const response = await contract.methods.getLockPair(account, tokenContract).call();

              if (response !== "0x0000000000000000000000000000000000000000") {
                console.log(tokenContract,response,"1234444");
                await fetchData(response)

                const contract = new web3.eth.Contract(lockTokenABA, response);
                const data = await contract.methods.getUserNumLocksForToken(account, tokenContract).call()
                // let arr = []
                let count = 0;

                for (let index = 0; index < data; index++) {
                  const getUserLockForTokenAtIndex = await contract.methods.getUserLockForTokenAtIndex(account, tokenContract, index).call()

                  if(getUserLockForTokenAtIndex) {
                    arr.map((i,index) => {
                      if(i.pair === tokenContract) {
                        arr[index].data.push({
                          "timeStamp": getUserLockForTokenAtIndex[0],
                          "amountHolding0": getUserLockForTokenAtIndex[1],
                          "amountHolding1": getUserLockForTokenAtIndex[2],
                          "to": getUserLockForTokenAtIndex[3],
                          index,
                          "userId": getUserLockForTokenAtIndex[5],
                          "lockContract": response,
                          "lockType": x,
                          "lockIndex": getUserLockForTokenAtIndex[4],
                          "lockIndexUI": noOfLockIndex++
                        })
                      }
                    })
                    count++;
                  }
                }
                if(count == data) {
                  updatePositionLocks(arr)
                }

              } else {
                count1++;
                updateLockedPositions(null)
              }

            }
          })
        })

      } else {
        let arr = []

        const tokenContract = position.pair.id;
        let noOfLockIndex = 1;
        arr.push({
          "pair": tokenContract,
          "token0": position.pair.token0.symbol,
          "token1": position.pair.token1.symbol,
          "reserveUSD": position.pair.reserveUSD,
          "data": []
        })
        listOfPoolsForLock.map(async x => {

          const contractAddress = x == listOfPoolsForLock[0] ? process.env['REACT_APP_LOCK_FACTORY_ADDRESS'] : process.env['REACT_APP_LOCK_FACTORY_ADDRESS_EXTICO'];

          if (contractAddress != "0x0000000000000000000000000000000000000000") {
            const contract = new web3.eth.Contract(lockFactoryABI, contractAddress);
            const response = await contract.methods.getLockPair(account, tokenContract).call();

            if (response !== "0x0000000000000000000000000000000000000000") {
              await fetchData(response)


              const contract = new web3.eth.Contract(lockTokenABA, response);
                const data = await contract.methods.getUserNumLocksForToken(account, tokenContract).call()
                let count = 0;

                for (let index = 0; index < data; index++) {
                  const getUserLockForTokenAtIndex = await contract.methods.getUserLockForTokenAtIndex(account, tokenContract, index).call()

                  if(getUserLockForTokenAtIndex) {
                    arr.map((i,index) => {
                      if(i.pair === tokenContract) {
                        arr[index].data.push({
                          "timeStamp": getUserLockForTokenAtIndex[0],
                          "amountHolding0": getUserLockForTokenAtIndex[1],
                          "amountHolding1": getUserLockForTokenAtIndex[2],
                          "to": getUserLockForTokenAtIndex[3],
                          index,
                          "userId": getUserLockForTokenAtIndex[5],
                          "lockContract": response,
                          "lockType": x,
                          "lockIndex": getUserLockForTokenAtIndex[4],
                          "lockIndexUI": noOfLockIndex++
                        })
                      }
                    })
                    count++;
                  }
                }
                if(count == data) {
                  updatePositionLocks(arr)
                }

            } else {
              count1++;
              updateLockedPositions(null)
            }
          }
        })
      }

    }
  }, [account, updateLockedPositions,updatePositionLocks, position, ethPrice])

  return [positions, locks]
}
