import { TokenInfo, TokenList } from "@panaromafinance/panaromaswap_v1tokenlist";
import { WrappedTokenInfo } from "state/lists/wrappedTokenInfo";

type TokenMap = Readonly<{
  [tokenAddress: string]: { token: WrappedTokenInfo; list?: TokenList };
}>;
export type ChainTokenMap = Readonly<{ [chainId: number]: TokenMap }>;

type Mutable<T> = {
  -readonly [P in keyof T]: Mutable<T[P]>;
};

const mapCache =
  typeof WeakMap !== "undefined"
    ? new WeakMap<TokenList | TokenInfo[], ChainTokenMap>()
    : null;

export function tokensToChainTokenMap(
  tokens: TokenList | TokenInfo[]
): ChainTokenMap {
  const cached = mapCache?.get(tokens);
  if (cached) return cached;

  const [list, infos] = Array.isArray(tokens)
    ? [undefined, tokens]
    : [tokens, tokens.tokens];
  const map = infos.reduce<Mutable<ChainTokenMap>>((map, info) => {
    try {
      const token = new WrappedTokenInfo(info, list);
      if (map[token.chainId]?.[token.address] !== undefined) {
        console.warn(`Duplicate token skipped: ${token.address}`);
        return map;
      }
      if (!map[token.chainId]) {
        map[token.chainId] = {};
      }
      map[token.chainId][token.address] = { token, list };
      return map;
    } catch {
      return map;
    }
  }, {}) as ChainTokenMap;
  mapCache?.set(tokens, map);
  return map;
}
