export const TOKEN_DATA = {
    "MATIC/PANA" : {
        "id" : "0x12e7e2e26bea30ae6ffc21b3e4046a157415c5b8",
        "base" : 0
    },
    "PANA/MATIC" : {
        "id" : "0x12e7e2e26bea30ae6ffc21b3e4046a157415c5b8",
        "base" : 1
    },
    "WBTC/PANA" : {
        "id" : "0x01bea27e17c561aac279020f8c602950f5b4ba15",
        "base" : 0
    },
    "PANA/WBTC" : {
        "id" : "0x01bea27e17c561aac279020f8c602950f5b4ba15",
        "base" : 1
    },
    "USDT/PANA" : {
        "id" : "0x0843fc0f08e3fde90de8e72bcff23555be636755",
        "base" : 1
    },
    "PANA/USDT" : {
        "id" : "0x0843fc0f08e3fde90de8e72bcff23555be636755",
        "base" : 0
    },
    "MATIC/USDC" : {
        "id" : "0x12c9852be7f2270917d3146571ffb95a93c440f8",
        "base" : 0
    },
    "USDC/MATIC" : {
        "id" : "0x12c9852be7f2270917d3146571ffb95a93c440f8",
        "base" : 1
    },
    "MATIC/SHIB" : {
        "id" : "0x25bca3648a15fcdcb53651ff0cfec46f98001acf",
        "base" : 0
    },
    "SHIB/MATIC" : {
        "id" : "0x25bca3648a15fcdcb53651ff0cfec46f98001acf",
        "base" : 1
    },
    "PANA/BAE" : {
        "id" : "0x51ddf4d70da636b7e2d6197eda8d99c1b89c5656",
        "base" : 0
    },
    "BAE/PANA" : {
        "id" : "0x51ddf4d70da636b7e2d6197eda8d99c1b89c5656",
        "base" : 1
    },
    "MATIC/USDT" : {
        "id" : "0x547907a18a7aec18e2af26cfab26e4078d22f29b",
        "base" : 0
    },
    "USDT/MATIC" : {
        "id" : "0x547907a18a7aec18e2af26cfab26e4078d22f29b",
        "base" : 1
    },
    "PANA/WETH" : {
        "id" : "0x58f49995ec802fd519a9699b23f9257ccc11b9aa",
        "base" : 0
    },
    "WETH/PANA" : {
        "id" : "0x58f49995ec802fd519a9699b23f9257ccc11b9aa",
        "base" : 1
    },
    "MATIC/BACL" : {
        "id" : "0x5ee169f4e5e2ff0f47d8f966c7dcd418eea792bc",
        "base" : 0
    },
    "BACL/MATIC" : {
        "id" : "0x5ee169f4e5e2ff0f47d8f966c7dcd418eea792bc",
        "base" : 1
    },
    "WBTC/USDC" : {
        "id" : "0x75bd6bf71f020f17edc83adf505d5ceed0d96e10",
        "base" : 0
    },
    "USDC/WBTC" : {
        "id" : "0x75bd6bf71f020f17edc83adf505d5ceed0d96e10",
        "base" : 1
    },
    "DAI/USDT" : {
        "id" : "0x7b563c07c288b769074e7ccdd67d315e0dbe5d86",
        "base" : 0
    },
    "USDT/DAI" : {
        "id" : "0x7b563c07c288b769074e7ccdd67d315e0dbe5d86",
        "base" : 1
    },
    "MATIC/WBTC" : {
        "id" : "0x81a9c78e64c290fae05e9a36deaf69dc70a3b68f",
        "base" : 0
    },
    "WBTC/MATIC" : {
        "id" : "0x81a9c78e64c290fae05e9a36deaf69dc70a3b68f",
        "base" : 1
    },
    "MATIC/WETH" : {
        "id" : "0x829c18260fb3d246ab7592eaba1c04f0dd765bde",
        "base" : 0
    },
    "WETH/MATIC" : {
        "id" : "0x829c18260fb3d246ab7592eaba1c04f0dd765bde",
        "base" : 1
    },
    "MATIC/BAE" : {
        "id" : "0x9b74205381193cc42429da19eb8deea11a7b3f80",
        "base" : 0
    },
    "BAE/MATIC" : {
        "id" : "0x9b74205381193cc42429da19eb8deea11a7b3f80",
        "base" : 1
    },
    "MATIC/DAI" : {
        "id" : "0xc0c2bfa1d874abef998aff9b12ddc6051d88b252",
        "base" : 0
    },
    "DAI/MATIC" : {
        "id" : "0xc0c2bfa1d874abef998aff9b12ddc6051d88b252",
        "base" : 1
    },
    "USDC/WETH" : {
        "id" : "0xd94489b1b8d08cb52258b2aef13cab30b13638e4",
        "base" : 0
    },
    "WETH/USDC" : {
        "id" : "0xd94489b1b8d08cb52258b2aef13cab30b13638e4",
        "base" : 1
    },
    "PANA/BACL" : {
        "id" : "0xe0e7db62d8aebd1838ff768e1e1a448c950d616d",
        "base" : 0
    },
    "BACL/PANA" : {
        "id" : "0xe0e7db62d8aebd1838ff768e1e1a448c950d616d",
        "base" : 1
    },
    "PANA/DAI" : {
        "id" : "0xebe46535d4c77d8b87ae73d47affa91940451dd5",
        "base" : 0
    },
    "DAI/PANA" : {
        "id" : "0xebe46535d4c77d8b87ae73d47affa91940451dd5",
        "base" : 1
    },
    "PANA/USDC" : {
        "id" : "0xf168c8b189e92469afc08f8cbf82315f2c70ea29",
        "base" : 1
    },
    "USDC/PANA" : {
        "id" : "0xf168c8b189e92469afc08f8cbf82315f2c70ea29",
        "base" : 0
    },
    "PANA/SHIB" : {
        "id" : "0xfbdb795258c86fb0670e681f201d18a94c0827a5",
        "base" : 0
    },
    "SHIB/PANA" : {
        "id" : "0xfbdb795258c86fb0670e681f201d18a94c0827a5",
        "base" : 1
    },
};