import React, { useState } from "react";

export default function useLoader(): React.ReactNode | any  {
  const [loading, setLoading] = useState(false);

  return [
    loading,
    () => setLoading(true),
    () => setLoading(false),
  ];
};