import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { SupportedChainId } from "constants/chains";

let url = JSON.parse(window.localStorage.getItem('PANASWAP'));

let clientUrl = 'https://gateway-arbitrum.network.thegraph.com/api/a49d9b42264cf15c9c737828e8cace8c/subgraphs/id/9JiwngTYJZfUc6qakjCPN58gk6CuBfFGKfUrazigb5ig'

export const client = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/karthikrameshpanaroma/bangknewtestnet',
    uri: clientUrl,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const ethClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/karthikrameshpanaroma/bangknewtestnet',
    // uri: clientUrl,
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/index-node/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://gateway-arbitrum.network.thegraph.com/api/a49d9b42264cf15c9c737828e8cace8c/subgraphs/id/9JiwngTYJZfUc6qakjCPN58gk6CuBfFGKfUrazigb5ig',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const ethV1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/karthikrameshpanaroma/bangknewtestnet'
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    // uri: 'https://api.thegraph.com/subgraphs/name/karthikrameshpanaroma/bangknew-blocks',
    uri: 'https://gateway-arbitrum.network.thegraph.com/api/a49d9b42264cf15c9c737828e8cace8c/subgraphs/id/GARJqUUevPtuJ2RwuX9QvCf4sivL5CmYNMp2WafNzMpk',
  }),
  cache: new InMemoryCache(),
})

export function useUpdateClient(network) {

  switch (network) {
    case SupportedChainId.ETHEREUM:
      return ethClient
    case SupportedChainId.ARBITRUM:
      return ethClient
    case SupportedChainId.OPTIMISM:
      return ethClient
    case SupportedChainId.POLYGON:
      return client
    default:
      return client
  }

}

export function useUpdateV1Client(network) {

  switch (network) {
    case SupportedChainId.ETHEREUM:
      return ethV1Client
    case SupportedChainId.ARBITRUM:
      return ethV1Client
    case SupportedChainId.OPTIMISM:
      return ethV1Client
    case SupportedChainId.POLYGON:
      return v1Client
    default:
      return v1Client
  }
  
}
