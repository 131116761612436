import { Currency } from "@panaromafinance/panaromaswap_sdkcore";

import { nativeOnChain, WRAPPED_NATIVE_CURRENCY } from "../constants/tokens";
import { supportedChainId } from "./supportedChainId";

export function unwrappedToken(currency: Currency): Currency {
  if (currency.isNative) return currency;
  const formattedChainId = supportedChainId(currency.chainId);
  if (
    formattedChainId &&
    WRAPPED_NATIVE_CURRENCY[formattedChainId]?.equals(currency)
  )
    return nativeOnChain(currency.chainId);
  return currency;
}
