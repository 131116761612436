// eslint-disable-next-line no-restricted-imports
import { t, Trans } from "@lingui/macro";
import { Currency, Token } from "@panaromafinance/panaromaswap_sdkcore";
import { useWeb3React } from "@web3-react/core";
import { EventName, ModalName } from "components/AmplitudeAnalytics/constants";
import { Trace } from "components/AmplitudeAnalytics/Trace";
import { sendEvent } from "components/analytics";
import useDebounce from "hooks/useDebounce";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useTheme from "hooks/useTheme";
import useToggle from "hooks/useToggle";
import useNativeCurrency from "lib/hooks/useNativeCurrency";
import { getTokenFilter } from "lib/hooks/useTokenList/filtering";
import {
  tokenComparator,
  useSortTokensByQuery
} from "lib/hooks/useTokenList/sorting";
import {
  ChangeEvent,
  KeyboardEvent,
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
// import { Edit } from "react-feather";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Text } from "rebass";
import { useAllTokenBalances } from "state/connection/hooks";
import styled from "styled-components/macro";
// import { SupportedChainId } from "constants/chains";
// import axios from "axios";
import {
  useAllTokens,
  useIsUserAddedToken,
  useSearchInactiveTokenLists,
  useToken
} from "../../hooks/Tokens";
// import { deserializeToken } from "../../state/user/hooks";
import { CloseIcon, ThemedText } from "../../theme";
import { isAddress } from "../../utils";
import Column from "../Column";
import Row, { RowBetween } from "../Row";
import CommonBases from "./CommonBases";
import CurrencyList from "./CurrencyList";
// import ImportRow from "./ImportRow";
import { PaddedColumn, SearchInput, Separator } from "./styleds";

const ContentWrapper = styled(Column)`
  width: 100%;
  flex: 1 1;
  position: relative;
`;

const Footer = styled.div`
  width: 100%;
  border-radius: 20px;
  padding: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  background-color: ${({ theme }) => theme.deprecated_bg1};
  border-top: 1px solid ${({ theme }) => theme.deprecated_bg2};
`;

interface CurrencySearchProps {
  isOpen: boolean;
  onDismiss: () => void;
  selectedCurrency?: Currency | null;
  onCurrencySelect: (currency: Currency) => void;
  otherSelectedCurrency?: Currency | null;
  showCommonBases?: boolean;
  showCurrencyAmount?: boolean;
  disableNonToken?: boolean;
  showManageView: () => void;
  showImportView: () => void;
  setImportToken: (token: Token) => void;
}

export function CurrencySearch({
  selectedCurrency,
  onCurrencySelect,
  otherSelectedCurrency,
  showCommonBases,
  showCurrencyAmount,
  disableNonToken,
  onDismiss,
  isOpen,
  showManageView,
  showImportView,
  setImportToken
}: CurrencySearchProps) {
  const { chainId } = useWeb3React();
  const theme = useTheme();

  const [tokenLoaderTimerElapsed, setTokenLoaderTimerElapsed] = useState(false);

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedQuery = useDebounce(searchQuery, 200);

  const [referralURL] = useState(process.env['REACT_APP_REFERRAL_BASE_URL']);
  // switch (chainId) {
  //   case SupportedChainId.ARBITRUM_ONE:
  //   case SupportedChainId.ARBITRUM_GOERLI:

  //     getTokenList("arbitrumList")
  //     break;
  //   case SupportedChainId.GOERLI:
  //   case SupportedChainId.MAINNET:

  //     getTokenList("ethList")
  //     break;
  //   case SupportedChainId.OPTIMISM:
  //   case SupportedChainId.OPTIMISTIC_GOERLI:
  //     getTokenList("optimismList")
  //     break;
  //   case SupportedChainId.POLYGON:
  //   case SupportedChainId.POLYGON_MUMBAI:
  //     getTokenList("polygonList")
  //     break;

  //   default:
  //     getTokenList("polygonList")
  // }

  const allTokens = useAllTokens();

  // if they input an address, use it
  const isAddressSearch = isAddress(debouncedQuery);

  const searchToken = useToken(debouncedQuery);

  const searchTokenIsAdded = useIsUserAddedToken(searchToken);

  useEffect(() => {
    if (isAddressSearch) {
      sendEvent({
        category: "Currency Select",
        action: "Search by address",
        label: isAddressSearch
      });
    }
  }, [isAddressSearch]);

  const [balances, balancesIsLoading] = useAllTokenBalances();

  const filteredTokens: Token[] = useMemo(() => {
    return Object.values(allTokens).filter(getTokenFilter(debouncedQuery));
  }, [allTokens, balances, debouncedQuery]);

  const sortedTokens: Token[] = useMemo(() => {
    void balancesIsLoading; // creates a new array once balances load to update hooks
    return [...filteredTokens].sort(tokenComparator.bind(null, balances));
  }, [balances, filteredTokens, balancesIsLoading]);


  const filteredSortedTokens = useSortTokensByQuery(
    debouncedQuery,
    sortedTokens
  );

  const native = useNativeCurrency();
  // console.log(native,chainId,"nativertrtrt");


  const filteredSortedTokensWithETH: Currency[] = useMemo(() => {
    // Use Celo ERC20 Implementation and exclude the native asset
    if (!native) {
      return filteredSortedTokens;
    }

    const s = debouncedQuery.toLowerCase().trim();
    if (native.symbol?.toLowerCase()?.indexOf(s) !== -1) {
      // Always bump the native token to the top of the list.
      return native
        ? [native, ...filteredSortedTokens.filter((t) => !t.equals(native))]
        : filteredSortedTokens;
    }
    return filteredSortedTokens;
  }, [debouncedQuery, native, filteredSortedTokens]);


  const handleCurrencySelect = useCallback(
    (currency: Currency) => {
      onCurrencySelect(currency);
      onDismiss();
    },
    [onDismiss, onCurrencySelect]
  );

  // function getTokenList(name: string) {
  //   axios
  //     .get(referralURL + name)
  //     .then(function (response) {
  //       const tokenList = response.data.message.tokens;
  //       const data = tokenList.map(deserializeToken)
  //       data.map((res: Token) => {
  //         const arr = Object.values(allTokens).map(t => t.address)
  //         if (!arr.includes(res.address)) {
  //           allTokens[res.address] = res
  //         }
  //       })
  //     });
  // }

  // clear the input on open
  useEffect(() => {
    if (isOpen) setSearchQuery("");
  }, [isOpen]);

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>();
  const handleInput = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    const checksummedInput = isAddress(input);
    setSearchQuery(checksummedInput || input);
    fixedList.current?.scrollTo(0);
  }, []);

  const handleEnter = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        const s = debouncedQuery.toLowerCase().trim();
        if (s === native?.symbol?.toLowerCase()) {
          handleCurrencySelect(native);
        } else if (filteredSortedTokensWithETH.length > 0) {
          if (
            filteredSortedTokensWithETH[0].symbol?.toLowerCase() ===
            debouncedQuery.trim().toLowerCase() ||
            filteredSortedTokensWithETH.length === 1
          ) {
            handleCurrencySelect(filteredSortedTokensWithETH[0]);
          }
        }
      }
    },
    [debouncedQuery, native, filteredSortedTokensWithETH, handleCurrencySelect]
  );

  // menu ui
  const [open, toggle] = useToggle(false);
  const node = useRef<HTMLDivElement>();
  useOnClickOutside(node, open ? toggle : undefined);

  // if no results on main list, show option to expand into inactive
  const filteredInactiveTokens = useSearchInactiveTokenLists(
    filteredTokens.length === 0 ||
      (debouncedQuery.length > 2 && !isAddressSearch)
      ? debouncedQuery
      : undefined
  );

  // Timeout token loader after 3 seconds to avoid hanging in a loading state.
  useEffect(() => {
    const tokenLoaderTimer = setTimeout(() => {
      setTokenLoaderTimerElapsed(true);
    }, 3000);
    return () => clearTimeout(tokenLoaderTimer);
  }, []);

  return (
    <ContentWrapper>
      <Trace
        name={EventName.TOKEN_SELECTOR_OPENED}
        modal={ModalName.TOKEN_SELECTOR}
        shouldLogImpression>
        <PaddedColumn gap="16px">
          <RowBetween>
            <Text fontWeight={500} fontSize={16}>
              <Trans>Select a token</Trans>
            </Text>
            <CloseIcon onClick={onDismiss} />
          </RowBetween>
          <Row>
            <SearchInput
              type="text"
              id="token-search-input"
              placeholder={t`Search name or paste address`}
              autoComplete="off"
              value={searchQuery}
              ref={inputRef as RefObject<HTMLInputElement>}
              onChange={handleInput}
              onKeyDown={handleEnter}
            />
          </Row>
          {showCommonBases && (
            <CommonBases
              chainId={chainId}
              onSelect={handleCurrencySelect}
              selectedCurrency={selectedCurrency}
              searchQuery={searchQuery}
              isAddressSearch={isAddressSearch}
            />
          )}
        </PaddedColumn>
        <Separator />
        {
      //   searchToken && !searchTokenIsAdded ? (
      //     user won't be able to add new tokens
      //     <Column style={{ padding: "20px 0", height: "100%" }}>
      //       <ImportRow
      //         token={searchToken}
      //         showImportView={showImportView}
      //         setImportToken={setImportToken}
      //       />
      //     </Column>
      //     <Column style={{ padding: "20px", height: "100%" }}>
      //       <ThemedText.DeprecatedMain
      //         color={theme.deprecated_text3}
      //         textAlign="center"
      //         mb="20px">
      //         <Trans>No results found.</Trans>
      //       </ThemedText.DeprecatedMain>
      //     </Column>
      //  ) : 
        filteredSortedTokens?.length > 0 ||
          filteredInactiveTokens?.length > 0 ? (
          <div style={{ flex: "1" }}>
            <AutoSizer disableWidth>
              {({ height }) => (
                <CurrencyList
                  height={height}
                  currencies={
                    disableNonToken
                      ? filteredSortedTokens
                      : filteredSortedTokensWithETH
                  }
                  otherListTokens={filteredInactiveTokens}
                  onCurrencySelect={handleCurrencySelect}
                  otherCurrency={otherSelectedCurrency}
                  selectedCurrency={selectedCurrency}
                  fixedListRef={fixedList}
                  showImportView={showImportView}
                  setImportToken={setImportToken}
                  showCurrencyAmount={showCurrencyAmount}
                  isLoading={balancesIsLoading && !tokenLoaderTimerElapsed}
                  searchQuery={searchQuery}
                  isAddressSearch={isAddressSearch}
                />
              )}
            </AutoSizer>
          </div>
        ) : (
          <Column style={{ padding: "20px", height: "100%" }}>
            <ThemedText.DeprecatedMain
              color={theme.deprecated_text3}
              textAlign="center"
              mb="20px">
              <Trans>No results found.</Trans>
            </ThemedText.DeprecatedMain>
          </Column>
        )}
        {/* <Footer>
          <Row justify="center">
            <ButtonText
              onClick={showManageView}
              color={theme.deprecated_primary1}
              className="list-token-manage-button">
              <RowFixed>
                <IconWrapper
                  size="16px"
                  marginRight="6px"
                  stroke={theme.deprecated_primaryText1}>
                  <Edit />
                </IconWrapper>
                <ThemedText.DeprecatedMain
                  color={theme.deprecated_primaryText1}>
                  <Trans>Manage Token Lists</Trans>
                </ThemedText.DeprecatedMain>
              </RowFixed>
            </ButtonText>
          </Row>
        </Footer> */}
      </Trace>
    </ContentWrapper>
  );
}
