import { Trans } from "@lingui/macro";
import { Currency, CurrencyAmount, Percent } from "@panaromafinance/panaromaswap_sdkcore";
import { Position } from "@panaromafinance/panaromaswap_v2edgesdk";
import { useWeb3React } from "@web3-react/core";
import { useToken } from "hooks/Tokens";
import { usePool } from "hooks/usePools";
import { useV2edgePositionFees } from "hooks/useV2edgePositionFees";
import { ReactNode, useCallback, useMemo } from "react";
import { useAppDispatch, useAppSelector } from "state/hooks";
import { PositionDetails } from "types/position";
import { unwrappedToken } from "utils/unwrappedToken";

import { AppState } from "../../index";
import { selectPercent } from "./actions";

export function useBurnV2edgeState(): AppState["burnV2edge"] {
  return useAppSelector((state) => state.burnV2edge);
}

export function useDerivedV2edgeBurnInfo(
  position?: PositionDetails,
  asWETH = false
): {
  position?: Position;
  liquidityPercentage?: Percent;
  liquidityValue0?: CurrencyAmount<Currency>;
  liquidityValue1?: CurrencyAmount<Currency>;
  feeValue0?: CurrencyAmount<Currency>;
  feeValue1?: CurrencyAmount<Currency>;
  outOfRange: boolean;
  error?: ReactNode;
} {
  const { account } = useWeb3React();
  const { percent } = useBurnV2edgeState();

  const token0 = useToken(position?.token0);
  const token1 = useToken(position?.token1);

  const [, pool] = usePool(
    token0 ?? undefined,
    token1 ?? undefined,
    position?.fee
  );

  const positionSDK = useMemo(
    () =>
      pool &&
      position?.liquidity &&
      typeof position?.tickLower === "number" &&
      typeof position?.tickUpper === "number"
        ? new Position({
            pool,
            liquidity: position.liquidity.toString(),
            tickLower: position.tickLower,
            tickUpper: position.tickUpper
          })
        : undefined,
    [pool, position]
  );

  const liquidityPercentage = new Percent(percent, 100);

  const discountedAmount0 = positionSDK
    ? liquidityPercentage.multiply(positionSDK.amount0.quotient).quotient
    : undefined;
  const discountedAmount1 = positionSDK
    ? liquidityPercentage.multiply(positionSDK.amount1.quotient).quotient
    : undefined;

  const liquidityValue0 =
    token0 && discountedAmount0
      ? CurrencyAmount.fromRawAmount(
          asWETH ? token0 : unwrappedToken(token0),
          discountedAmount0
        )
      : undefined;
  const liquidityValue1 =
    token1 && discountedAmount1
      ? CurrencyAmount.fromRawAmount(
          asWETH ? token1 : unwrappedToken(token1),
          discountedAmount1
        )
      : undefined;

  const [feeValue0, feeValue1] = useV2edgePositionFees(
    pool ?? undefined,
    position?.tokenId,
    asWETH
  );

  const outOfRange =
    pool && position
      ? pool.tickCurrent < position.tickLower ||
        pool.tickCurrent > position.tickUpper
      : false;

  let error: ReactNode | undefined;
  if (!account) {
    error = <Trans>Connect Wallet</Trans>;
  }
  if (percent === 0) {
    error = error ?? <Trans>Enter a percent</Trans>;
  }
  return {
    position: positionSDK,
    liquidityPercentage,
    liquidityValue0,
    liquidityValue1,
    feeValue0,
    feeValue1,
    outOfRange,
    error
  };
}

export function useBurnV2edgeActionHandlers(): {
  onPercentSelect: (percent: number) => void;
} {
  const dispatch = useAppDispatch();

  const onPercentSelect = useCallback(
    (percent: number) => {
      dispatch(selectPercent({ percent }));
    },
    [dispatch]
  );

  return {
    onPercentSelect
  };
}
