import { Currency, CurrencyAmount, TradeType } from "@panaromafinance/panaromaswap_sdkcore";
import { Route, SwapQuoter } from "@panaromafinance/panaromaswap_v2edgesdk";
import { useWeb3React } from "@web3-react/core";
import { SupportedChainId } from "constants/chains";
import JSBI from "jsbi";
import { useSingleContractWithCallData } from "lib/hooks/multicall";
import { useMemo } from "react";
import { InterfaceTrade, TradeState } from "state/routing/types";

import { isCelo } from "../constants/tokens";
import { useAllV2edgeRoutes } from "./useAllV2edgeRoutes";
import { useQuoter } from "./useContract";

const QUOTE_GAS_OVERRIDES: { [chainId: number]: number } = {
  [SupportedChainId.ARBITRUM_ONE]: 25_000_000,
  [SupportedChainId.ARBITRUM_GOERLI]: 25_000_000,
  [SupportedChainId.CELO]: 50_000_000,
  [SupportedChainId.CELO_ALFAJORES]: 50_000_000,
  [SupportedChainId.POLYGON]: 40_000_000,
  [SupportedChainId.POLYGON_MUMBAI]: 40_000_000,
  [SupportedChainId.ROPSTEN]: 50_000_000
};

const DEFAULT_GAS_QUOTE = 2_000_000;

/**
 * Returns the best v2edge trade for a desired swap
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useClientSideV2edgeTrade<TTradeType extends TradeType>(
  tradeType: TTradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState;
  trade: InterfaceTrade<Currency, Currency, TTradeType> | undefined;
} {
  const [currencyIn, currencyOut] =
    tradeType === TradeType.EXACT_INPUT
      ? [amountSpecified?.currency, otherCurrency]
      : [otherCurrency, amountSpecified?.currency];
  const { routes, loading: routesLoading } = useAllV2edgeRoutes(
    currencyIn,
    currencyOut
  );

  const { chainId } = useWeb3React();
  // Chains deployed using the deploy-v2edge script only deploy QuoterV2.
  const useQuoterV2 = useMemo(
    () => Boolean(chainId && isCelo(chainId)),
    [chainId]
  );
  const quoter = useQuoter(useQuoterV2);
  const callData = useMemo(
    () =>
      amountSpecified
        ? routes.map(
            (route) =>
              SwapQuoter.quoteCallParameters(
                route,
                amountSpecified,
                tradeType,
                { useQuoterV2 }
              ).calldata
          )
        : [],
    [amountSpecified, routes, tradeType, useQuoterV2]
  );

  // console.log(quoter, callData)
  const quotesResults = useSingleContractWithCallData(quoter, callData, {
    gasRequired: chainId
      ? QUOTE_GAS_OVERRIDES[chainId] ?? DEFAULT_GAS_QUOTE
      : undefined
  });
  // console.log(quotesResults)

  return useMemo(() => {
    if (
      !amountSpecified ||
      !currencyIn ||
      !currencyOut ||
      quotesResults.some(({ valid }) => !valid) ||
      // skip when tokens are the same
      (tradeType === TradeType.EXACT_INPUT
        ? amountSpecified.currency.equals(currencyOut)
        : amountSpecified.currency.equals(currencyIn))
    ) {
      return {
        state: TradeState.INVALID,
        trade: undefined
      };
    }

    if (routesLoading || quotesResults.some(({ loading }) => loading)) {
      return {
        state: TradeState.LOADING,
        trade: undefined
      };
    }

    const { bestRoute, amountIn, amountOut } = quotesResults.reduce(
      (
        currentBest: {
          bestRoute: Route<Currency, Currency> | null;
          amountIn: CurrencyAmount<Currency> | null;
          amountOut: CurrencyAmount<Currency> | null;
        },
        { result },
        i
      ) => {
        if (!result) return currentBest;

        // overwrite the current best if it's not defined or if this route is better
        if (tradeType === TradeType.EXACT_INPUT) {
          const amountOut = CurrencyAmount.fromRawAmount(
            currencyOut,
            result.amountOut.toString()
          );
          if (
            currentBest.amountOut === null ||
            JSBI.lessThan(currentBest.amountOut.quotient, amountOut.quotient)
          ) {
            return {
              bestRoute: routes[i],
              amountIn: amountSpecified,
              amountOut
            };
          }
        } else {
          const amountIn = CurrencyAmount.fromRawAmount(
            currencyIn,
            result.amountIn.toString()
          );
          if (
            currentBest.amountIn === null ||
            JSBI.greaterThan(currentBest.amountIn.quotient, amountIn.quotient)
          ) {
            return {
              bestRoute: routes[i],
              amountIn,
              amountOut: amountSpecified
            };
          }
        }
        // console.log(currentBest)
        return currentBest;
      },
      {
        bestRoute: null,
        amountIn: null,
        amountOut: null
      }
    );
    // console.log(bestRoute, amountIn, amountOut);
    if (!bestRoute || !amountIn || !amountOut) {
      // console.log("trade");
      return {
        state: TradeState.NO_ROUTE_FOUND,
        trade: undefined
      };
    }
    // console.log(bestRoute, amountIn, amountOut)
    return {
      state: TradeState.VALID,
      trade: new InterfaceTrade({
        v1Routes: [],
        v2edgeRoutes: [
          {
            routev2edge: bestRoute,
            inputAmount: amountIn,
            outputAmount: amountOut
          }
        ],
        tradeType
      })
    };
  }, [
    amountSpecified,
    currencyIn,
    currencyOut,
    quotesResults,
    routes,
    routesLoading,
    tradeType
  ]);
}
