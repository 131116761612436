import { BigNumber } from "@ethersproject/bignumber";
import { Contract } from "@ethersproject/contracts";
import { TransactionResponse } from "@ethersproject/providers";
import { Trans } from "@lingui/macro";
import { Currency, Percent } from "@panaromafinance/panaromaswap_sdkcore";
import { useWeb3React } from "@web3-react/core";
import {
  ElementName,
  Event,
  EventName
} from "components/AmplitudeAnalytics/constants";
import { TraceEvent } from "components/AmplitudeAnalytics/TraceEvent";
import { sendEvent } from "components/analytics";
import { useV1LiquidityTokenPermit } from "hooks/useV1LiquidityTokenPermit";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ArrowDown, Plus } from "react-feather";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "rebass";
import { ThemeContext } from "styled-components/macro";

import {
  ButtonConfirmed,
  ButtonError,
  ButtonLight,
  ButtonPrimary
} from "../../components/Button";
import { BlueCard, LightCard } from "../../components/Card";
import { AutoColumn, ColumnCenter } from "../../components/Column";
import CurrencyInputPanel from "../../components/CurrencyInputPanel";
import CurrencyLogo from "../../components/CurrencyLogo";
import DoubleCurrencyLogo from "../../components/DoubleLogo";
import { AddRemoveTabs } from "../../components/NavigationTabs";
import { MinimalPositionCard } from "../../components/PositionCard";
import Row, { RowBetween, RowFixed } from "../../components/Row";
import Slider from "../../components/Slider";
import { Dots } from "../../components/swap/styleds";
import TransactionConfirmationModal, {
  ConfirmationModalContent
} from "../../components/TransactionConfirmationModal";
import { WRAPPED_NATIVE_CURRENCY } from "../../constants/tokens";
import { useCurrency } from "../../hooks/Tokens";
import {
  ApprovalState,
  useApproveCallback
} from "../../hooks/useApproveCallback";
import { usePairContract, useV1RouterContract } from "../../hooks/useContract";
import useDebouncedChangeHandler from "../../hooks/useDebouncedChangeHandler";
import useTransactionDeadline from "../../hooks/useTransactionDeadline";
import { useToggleWalletModal } from "../../state/application/hooks";
import { Field } from "../../state/burn/actions";
import {
  useBurnActionHandlers,
  useBurnState,
  useDerivedBurnInfo
} from "../../state/burn/hooks";
import { useTransactionAdder } from "../../state/transactions/hooks";
import { TransactionType } from "../../state/transactions/types";
import { useUserSlippageToleranceWithDefault } from "../../state/user/hooks";
import { StyledInternalLink, ThemedText } from "../../theme";
import { calculateGasMargin } from "../../utils/calculateGasMargin";
import { calculateSlippageAmount } from "../../utils/calculateSlippageAmount";
import { currencyId } from "../../utils/currencyId";
import AppBody from "../AppBody";
import { ClickableText, MaxButton, Wrapper } from "../Pool/styleds";
import axios from "axios";
import { addPopup, removePopup } from "state/application/reducer";
import { useAppDispatch, useAppSelector } from "state/hooks";
import Web3 from "web3";
import { SupportedChainId } from "constants/chains";
import lockFactoryBalanceABI from "../../abis/lockFactoryBalanceABI.json"
import { RPC_URLS } from "constants/networks";
import lockBalanceABI from "../../abis/lockBalanceABI.json"
import useLoader from "hooks/useLoader";
import { useNativeCurrencyBalances } from "lib/hooks/useCurrencyBalance";

const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, 100);

export default function RemoveLiquidity() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { currencyIdA, currencyIdB } = useParams<{
    currencyIdA: string;
    currencyIdB: string;
  }>();
  const [currencyA, currencyB] = [
    useCurrency(currencyIdA) ?? undefined,
    useCurrency(currencyIdB) ?? undefined
  ];
  const { account, chainId, provider } = useWeb3React();
  const [tokenA, tokenB] = useMemo(
    () => [currencyA?.wrapped, currencyB?.wrapped],
    [currencyA, currencyB]
  );

  const theme = useContext(ThemeContext);

  // toggle wallet when disconnected
  const toggleWalletModal = useToggleWalletModal();

  // burn state
  const { independentField, typedValue } = useBurnState();
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(
    currencyA ?? undefined,
    currencyB ?? undefined
  );
  const { onUserInput: _onUserInput } = useBurnActionHandlers();
  const isValid = !error;

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showDetailed, setShowDetailed] = useState<boolean>(false);
  const [attemptingTxn, setAttemptingTxn] = useState(false); // clicked confirm

  const [removePercentage, setRemovePercentage] = useState(100);

  const DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE = new Percent(5, removePercentage);

  // txn values
  const [txHash, setTxHash] = useState<string>("");
  const deadline = useTransactionDeadline();
  const allowedSlippage = useUserSlippageToleranceWithDefault(
    DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE
  );

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]: parsedAmounts[Field.LIQUIDITY_PERCENT].equalTo(
      "0"
    )
      ? "0"
      : parsedAmounts[Field.LIQUIDITY_PERCENT].lessThan(new Percent("1", removePercentage))
        ? "<1"
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY
        ? typedValue
        : parsedAmounts[Field.LIQUIDITY]?.toSignificant(6) ?? "",
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A
        ? typedValue
        : parsedAmounts[Field.CURRENCY_A]?.toSignificant(6) ?? "",
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B
        ? typedValue
        : parsedAmounts[Field.CURRENCY_B]?.toSignificant(6) ?? ""
  };

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT]?.equalTo(
    new Percent("1")
  );

  // pair contract
  const pairContract: Contract | null = usePairContract(
    pair?.liquidityToken?.address
  );

  const router = useV1RouterContract();

  // allowance handling
  const { gatherPermitSignature, signatureData } = useV1LiquidityTokenPermit(
    parsedAmounts[Field.LIQUIDITY],
    router?.address
  );
  const [approval, approveCallback] = useApproveCallback(
    parsedAmounts[Field.LIQUIDITY],
    router?.address,
    false
  );

  async function onAttemptToApprove() {
    if (!pairContract || !pair || !provider || !deadline)
      throw new Error("missing dependencies");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");

    if (gatherPermitSignature) {
      try {
        await gatherPermitSignature();
      } catch (error) {
        // try to approve if gatherPermitSignature failed for any reason other than the user rejecting it
        if (error?.code !== 4001) {
          try {
            await approveCallback();
          } catch (error) {
            dispatch(
              addPopup({
                content: { rejectAction: error.code ? error.code : "Failed" },
                key: `reject-action`
              })
            )
          }
        } else {
          dispatch(
            addPopup({
              content: { rejectAction: error.message ? error.message : "Failed" },
              key: `reject-action`
            })
          )
        }
      }
    } else {
      try {
        await approveCallback();
      } catch (error) {
        dispatch(
          addPopup({
            content: { rejectAction: error.code ? error.code : "Failed" },
            key: `reject-action`
          })
        )
      }
    }
  }

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue);
    },
    [_onUserInput]
  );

  const onLiquidityInput = useCallback(
    (typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue),
    [onUserInput]
  );
  const onCurrencyAInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue),
    [onUserInput]
  );
  const onCurrencyBInput = useCallback(
    (typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue),
    [onUserInput]
  );

  // tx sending
  const addTransaction = useTransactionAdder();

  async function onRemove() {
    if (!chainId || !provider || !account || !deadline || !router)
      throw new Error("missing dependencies");
    const {
      [Field.CURRENCY_A]: currencyAmountA,
      [Field.CURRENCY_B]: currencyAmountB
    } = parsedAmounts;
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error("missing currency amounts");
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(
        currencyAmountA,
        allowedSlippage
      )[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(
        currencyAmountB,
        allowedSlippage
      )[0]
    };

    if (!currencyA || !currencyB) throw new Error("missing tokens");
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY];
    if (!liquidityAmount) throw new Error("missing liquidity amount");

    const currencyBIsETH = currencyB.isNative;
    const oneCurrencyIsETH = currencyA.isNative || currencyBIsETH;

    if (!tokenA || !tokenB) throw new Error("could not wrap");

    let methodNames: string[],
      args: Array<string | string[] | number | boolean>;
    // we have approval, use normal remove liquidity
    if (approval === ApprovalState.APPROVED) {
      // removeLiquidityETH
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETH",
          "removeLiquidityETHSupportingFeeOnTransferTokens"
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          account,
          deadline.toHexString()
        ];
      }
      // removeLiquidity
      else {
        methodNames = ["removeLiquidity"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          deadline.toHexString()
        ];
      }
    }
    // we have a signature, use permit versions of remove liquidity
    else if (signatureData !== null) {
      // removeLiquidityETHWithPermit
      if (oneCurrencyIsETH) {
        methodNames = [
          "removeLiquidityETHWithPermit",
          "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens"
        ];
        args = [
          currencyBIsETH ? tokenA.address : tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_A : Field.CURRENCY_B
          ].toString(),
          amountsMin[
            currencyBIsETH ? Field.CURRENCY_B : Field.CURRENCY_A
          ].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s
        ];
      }
      // removeLiquidityETHWithPermit
      else {
        methodNames = ["removeLiquidityWithPermit"];
        args = [
          tokenA.address,
          tokenB.address,
          liquidityAmount.quotient.toString(),
          amountsMin[Field.CURRENCY_A].toString(),
          amountsMin[Field.CURRENCY_B].toString(),
          account,
          signatureData.deadline,
          false,
          signatureData.v,
          signatureData.r,
          signatureData.s
        ];
      }
    } else {
      throw new Error(
        "Attempting to confirm without approval or a signature. Please contact support."
      );
    }

    const safeGasEstimates: (BigNumber | undefined)[] = await Promise.all(
      methodNames.map((methodName) =>
        router.estimateGas[methodName](...args)
          .then((estimateGas) => calculateGasMargin(estimateGas))
          .catch((error) => {
            console.error(`estimateGas failed`, methodName, args, error);
            return undefined;
          })
      )
    );

    const indexOfSuccessfulEstimation = safeGasEstimates.findIndex(
      (safeGasEstimate) => BigNumber.isBigNumber(safeGasEstimate)
    );

    // all estimations failed...
    if (indexOfSuccessfulEstimation === -1) {
      console.error("This transaction would fail. Please contact support.");
    } else {
      const methodName = methodNames[indexOfSuccessfulEstimation];
      const safeGasEstimate = safeGasEstimates[indexOfSuccessfulEstimation];

      setAttemptingTxn(true);
      await router[methodName](...args, {
        gasLimit: safeGasEstimate
      })
        .then((response: TransactionResponse) => {
          setAttemptingTxn(false);

          addTransaction(response, {
            type: TransactionType.REMOVE_LIQUIDITY_V2edge,
            baseCurrencyId: currencyId(currencyA),
            quoteCurrencyId: currencyId(currencyB),
            expectedAmountBaseRaw:
              parsedAmounts[Field.CURRENCY_A]?.quotient.toString() ?? "0",
            expectedAmountQuoteRaw:
              parsedAmounts[Field.CURRENCY_B]?.quotient.toString() ?? "0"
          });

          setTxHash(response.hash);

          sendEvent({
            category: "Liquidity",
            action: "Remove",
            label: [currencyA.symbol, currencyB.symbol].join("/")
          });
        })
        .catch((error: any) => {
          setAttemptingTxn(false);
          // we only care if the error is something _other_ than the user rejected the tx
          console.error(error);

          dispatch(
            addPopup({
              content: { rejectAction: error.code ? error.code : "Failed" },
              key: `reject-action`
            })
          )
        });
    }
  }

  function modalHeader() {
    return (
      <AutoColumn gap={"md"} style={{ marginTop: "20px" }}>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyA} size={"24px"} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: "10px" }}>
              {currencyA?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>
        <RowFixed>
          <Plus size="16" color={theme.deprecated_text2} />
        </RowFixed>
        <RowBetween align="flex-end">
          <Text fontSize={24} fontWeight={500}>
            {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)}
          </Text>
          <RowFixed gap="4px">
            <CurrencyLogo currency={currencyB} size={"24px"} />
            <Text fontSize={24} fontWeight={500} style={{ marginLeft: "10px" }}>
              {currencyB?.symbol}
            </Text>
          </RowFixed>
        </RowBetween>

        <ThemedText.DeprecatedItalic
          fontSize={12}
          color={theme.deprecated_text2}
          textAlign="left"
          padding={"12px 0 0 0"}>
          <Trans>
            Output is estimated. If the price changes by more than{" "}
            {allowedSlippage.toSignificant(4)}% your transaction will revert.
          </Trans>
        </ThemedText.DeprecatedItalic>
      </AutoColumn>
    );
  }

  function modalBottom() {
    return (
      <>
        <RowBetween>
          <Text color={theme.deprecated_text2} fontWeight={500} fontSize={16}>
            <Trans>
              PANA {currencyA?.symbol}/{currencyB?.symbol} Burned
            </Trans>
          </Text>
          <RowFixed>
            <DoubleCurrencyLogo
              currency0={currencyA}
              currency1={currencyB}
              margin={true}
            />
            <Text fontWeight={500} fontSize={16}>
              {parsedAmounts[Field.LIQUIDITY]?.toSignificant(6)}
            </Text>
          </RowFixed>
        </RowBetween>
        {pair && (
          <>
            <RowBetween>
              <Text
                color={theme.deprecated_text2}
                fontWeight={500}
                fontSize={16}>
                <Trans>Price</Trans>
              </Text>
              <Text
                fontWeight={500}
                fontSize={16}
                color={theme.deprecated_text1}>
                1 {currencyA?.symbol} ={" "}
                {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                {currencyB?.symbol}
              </Text>
            </RowBetween>
            <RowBetween>
              <div />
              <Text
                fontWeight={500}
                fontSize={16}
                color={theme.deprecated_text1}>
                1 {currencyB?.symbol} ={" "}
                {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                {currencyA?.symbol}
              </Text>
            </RowBetween>
          </>
        )}
        <ButtonPrimary
          disabled={
            !(approval === ApprovalState.APPROVED || signatureData !== null)
          }
          onClick={onRemove}>
          <Text fontWeight={500} fontSize={20}>
            <Trans>Confirm</Trans>
          </Text>
        </ButtonPrimary>
      </>
    );
  }

  const pendingText = (
    <Trans>
      Removing {parsedAmounts[Field.CURRENCY_A]?.toSignificant(6)}{" "}
      {currencyA?.symbol} and
      {parsedAmounts[Field.CURRENCY_B]?.toSignificant(6)} {currencyB?.symbol}
    </Trans>
  );

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString());
    },
    [onUserInput]
  );

  const oneCurrencyIsETH = currencyA?.isNative || currencyB?.isNative;

  const oneCurrencyIsWETH = Boolean(
    chainId &&
    WRAPPED_NATIVE_CURRENCY[chainId] &&
    ((currencyA && WRAPPED_NATIVE_CURRENCY[chainId]?.equals(currencyA)) ||
      (currencyB && WRAPPED_NATIVE_CURRENCY[chainId]?.equals(currencyB)))
  );

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        navigate(`/remove/v1/${currencyId(currency)}/${currencyIdA}`);
      } else {
        navigate(`/remove/v1/${currencyId(currency)}/${currencyIdB}`);
      }
    },
    [currencyIdA, currencyIdB, navigate]
  );
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        navigate(`/remove/v1/${currencyIdB}/${currencyId(currency)}`);
      } else {
        navigate(`/remove/v1/${currencyIdA}/${currencyId(currency)}`);
      }
    },
    [currencyIdA, currencyIdB, navigate]
  );

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false);
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, "0");
    }
    setTxHash("");
  }, [onUserInput, txHash]);

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] =
    useDebouncedChangeHandler(
      Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
      liquidityPercentChangeCallback
    );

  const [token, setToken] = useState("");
  const [errors, setError] = useState("");
  const [showAuth, setShowAuth] = useState(false);
  const [baseGAuthURL] = useState(process.env['REACT_APP_GAUTH_BASE_URL']);
  const [disbtn, setDisbtn] = useState(false);
  const [is2FAVerified, setIs2FAVerified] = useState(false);

  const checkAuth = () => {
    // console.log(account, "select address");
    const payload = { metaMaskPrivateKey: account };
    // console.log(payload, "payload");
    axios
      .get(baseGAuthURL + "checkauthenticator/" + account)
      .then(function (response) {
        // console.log("&&&&&&&& response", response);
        if (response.data.status === 1) {
          setShowAuth(true);
          setDisbtn(true)

          // setDirectSwap(false);
        } else if (response.data.status === 2) {
          setShowAuth(false);
          setIs2FAVerified(true);
          // setDirectSwap(true);
        } else if (response.data.status === 0) {
          setShowAuth(false);
          setDisbtn(false);
          setIs2FAVerified(true);
          // setDirectSwap(true);
        }
      }).catch((error) => {
        dispatch(
          addPopup({
            content: { rejectAction: error.message ? error.message : "Failed" },
            key: `reject-action`
          })
        )
      });
  };

  const verify = () => {
    const payload = { token, metaMaskPrivateKey: account };
    axios
      .post(baseGAuthURL + "verify", {
        method: "POST",
        metaMaskPrivateKey: account,
        token,
        headers: {
          // 'Authorization': `bearer ${token}`,
          "Content-Type": "application/json"
        }
      })
      .then(function (response) {
        // console.log("@@@@ response", response);
        if (response.data) {
          setDisbtn(false);
          setError("");
          setShowAuth(false);
          setIs2FAVerified(true);
        } else {
          setIs2FAVerified(false);
          setError("Invalid Code. Please enter the correct code from authenticator");
        }
      }).catch((error) => {
        dispatch(
          addPopup({
            content: { rejectAction: error.message ? error.message : "Failed" },
            key: `reject-action`
          })
        )
      });
  };

  const rpcurlQuickNode = useAppSelector((state) => state.user.rpcUrl)
  const rpcurl = rpcurlQuickNode ? rpcurlQuickNode : RPC_URLS;
  const listOfPoolsForLock = ["SWAP", "PANAICO", "EXTICO"];

  let web3 = new Web3(
    new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
  );

  switch (chainId) {
    case SupportedChainId.ARBITRUM_ONE:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.ARBITRUM_ONE])
      );
      break;
    case SupportedChainId.ARBITRUM_GOERLI:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.ARBITRUM_GOERLI])
      );
      break;
    case SupportedChainId.GOERLI:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.GOERLI])
      );
      break;
    case SupportedChainId.MAINNET:
      // console.log("11111 RPC_URLS['1']", RPC_URLS["1"]);
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.MAINNET])
      );
      break;
    case SupportedChainId.OPTIMISM:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.OPTIMISM])
      );
      break;
    case SupportedChainId.OPTIMISTIC_GOERLI:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.OPTIMISTIC_GOERLI])
      );
      break;
    case SupportedChainId.POLYGON:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.POLYGON])
      );
      break;
    case SupportedChainId.POLYGON_MUMBAI:
      web3 = new Web3(
        new Web3.providers.HttpProvider(rpcurl[SupportedChainId.POLYGON_MUMBAI])
      );
      break;
    default:
      web3 = new Web3(
        new Web3.providers.HttpProvider("https://rpc-mumbai.maticvigil.com/")
      );
  }

  const lockTockenBalanceABIJson = lockFactoryBalanceABI as any;

  async function checklockedFactoryTokens() {
    if (pair) {
      let userLockedBalance = 0;

      listOfPoolsForLock.map(async x => {

        const contractAddress = x == listOfPoolsForLock[0] ? process.env['REACT_APP_LOCK_FACTORY_ADDRESS'] :
          x == listOfPoolsForLock[1] ? process.env['REACT_APP_LOCK_FACTORY_ADDRESS_PANAICO'] :
            process.env['REACT_APP_LOCK_FACTORY_ADDRESS_EXTICO'];

        if (contractAddress != "0x0000000000000000000000000000000000000000") {


          const contract = new web3.eth.Contract(lockTockenBalanceABIJson, contractAddress);

          const response = await contract.methods
            .getLockPair(account, pair.liquidityToken.address)
            .call();

          const lockedFundAddress = response;

          if (lockedFundAddress == 0x0000000000000000000000000000000000000000) {
            localStorage.setItem("lockedFactoryTokens", lockedFundAddress);
            // setLockedTokens(0);
            const tokenContract = pair.liquidityToken.address;
            const tokenHolder = localStorage.getItem("lockedFactoryTokens");

          } else {

            localStorage.setItem("lockedFactoryTokens", lockedFundAddress);
            const balanceOfABI = lockBalanceABI as any;
            const tokenContract = pair.liquidityToken.address;
            const tokenHolder = lockedFundAddress;
            // console.log(tokenContract, "tokenContract", tokenHolder, "tokenHolder");	
            const contract = new web3.eth.Contract(
              balanceOfABI,
              tokenContract.toString()
            );

            const result = await contract.methods
              .balanceOf(tokenHolder.toString())
              .call();

            const userLockedBalanceResult = web3.utils.fromWei(result);
            userLockedBalance = userLockedBalance + Number(userLockedBalanceResult);

            setRemovePercentage(Number(userLockedBalance) > 0 ? 99 : 100)

            // setLockedTokens(userLockedBalance.toFixed(18));

            // pooledCurrency0 = pooledCurrency0 + (Number(userLockedBalance ? userLockedBalance : 0) * (Number(token0Deposited ? token0Deposited?.toSignificant(pair.token0.decimals) : 0) / Number(userPoolBalance ? userPoolBalance?.toSignificant(pair.token0.decimals) : 0)));
            // pooledCurrency1 = pooledCurrency1 + (Number(userLockedBalance ? userLockedBalance : 0) * (Number(token1Deposited ? token1Deposited?.toSignificant(pair.token1.decimals) : 0) / Number(userPoolBalance ? userPoolBalance?.toSignificant(pair.token1.decimals) : 0)));
            // pooledLockShare = pooledLockShare + (Number(userLockedBalance ? userLockedBalance : 0) * (Number(poolTokenPercentage ? poolTokenPercentage?.toSignificant(6) : 0) / Number(userPoolBalance ? userPoolBalance?.toSignificant(6) : 0)));

            // setLockedCurrency0(Number(pooledCurrency0).toFixed(pair.token0.decimals));
            // setLockedCurrency1(Number(pooledCurrency1).toFixed(pair.token1.decimals));
            // setLockedPoolShare(Number(pooledLockShare).toFixed(2));
          }
        }
      })
    }
    else {
      // setRemovePercentage(100)

      // setTimeout(() => {
      //   console.log("1111 0.01 userLockedBalance else", removePercentage);
      // }, 2000);

    }

    console.log("1111 0.01 userLockedBalance else", removePercentage);

  }

  useEffect(() => {
    checkAuth();
    checklockedFactoryTokens();
  }, [account, pair])

  const userEthBalance = useNativeCurrencyBalances(account ? [account] : [])?.[
      account ?? ""
  ];

  const [loading, showLoader, hideLoader] = useLoader();

  useEffect(() => {
      if(userEthBalance && account) {
          hideLoader();
      } else if(account){
          showLoader()
      }
  },[userEthBalance])

  useEffect(() => {
    if(loading) {
      dispatch(
          addPopup({
              content: { loaderAction: "Fetching wallet balance. Please wait until balance loads." },
              key: `loader-action`
          })
      )
    } else {
      dispatch(
        removePopup({
            key: `loader-action`
        })
      )
    }
  },[loading])

  return (
    <>
      <div className="d-lg-flex">
        {pair ? (
          <AutoColumn
            style={{
              minWidth: "20rem",
              width: "100%",
              maxWidth: "400px",
              margin: "0 auto",
              height: "fit-content"
            }}>
            <MinimalPositionCard showUnwrapped={oneCurrencyIsWETH} pair={pair} />
          </AutoColumn>
        ) : null}
        <AppBody>
          <AddRemoveTabs
            creating={false}
            adding={false}
            defaultSlippage={DEFAULT_REMOVE_LIQUIDITY_SLIPPAGE_TOLERANCE}
          />
          <Wrapper>
            <TransactionConfirmationModal
              isOpen={showConfirm}
              onDismiss={handleDismissConfirmation}
              attemptingTxn={attemptingTxn}
              hash={txHash ? txHash : ""}
              content={() => (
                <ConfirmationModalContent
                  title={<Trans>You will receive</Trans>}
                  onDismiss={handleDismissConfirmation}
                  topContent={modalHeader}
                  bottomContent={modalBottom}
                />
              )}
              pendingText={pendingText}
              transactionType="REMOVE"
              currencyA={currencyA}
              currencyB={currencyB}
            />
            <AutoColumn gap="md">
              <BlueCard>
                <AutoColumn gap="10px">
                  <ThemedText.DeprecatedLink
                    fontWeight={400}
                    color={"deprecated_primaryText1"}>
                    <Trans>
                      <b>Tip:</b> Removing pool tokens converts your position back
                      into underlying tokens at the current rate, proportional to
                      your share of the pool. Accrued fees are included in the
                      amounts you receive.
                    </Trans>
                  </ThemedText.DeprecatedLink>
                </AutoColumn>
              </BlueCard>
              <LightCard>
                <AutoColumn gap="20px">
                  <RowBetween>
                    <Text fontWeight={500}>
                      <Trans>Remove Amount</Trans>
                    </Text>
                    <ClickableText
                      fontWeight={500}
                      onClick={() => {
                        setShowDetailed(!showDetailed);
                      }}>
                      {showDetailed ? (
                        <Trans>Simple</Trans>
                      ) : (
                        <Trans>Detailed</Trans>
                      )}
                    </ClickableText>
                  </RowBetween>
                  <Row style={{ alignItems: "flex-end" }}>
                    <Text fontSize={72} fontWeight={500}>
                      {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                    </Text>
                  </Row>
                  {!showDetailed && (
                    <>
                      <Slider
                        value={innerLiquidityPercentage}
                        onChange={setInnerLiquidityPercentage}
                        max={removePercentage}
                      />
                      <RowBetween>
                        <MaxButton
                          onClick={() =>
                            onUserInput(Field.LIQUIDITY_PERCENT, "25")
                          }
                          width="20%">
                          25%
                        </MaxButton>
                        <MaxButton
                          onClick={() =>
                            onUserInput(Field.LIQUIDITY_PERCENT, "50")
                          }
                          width="20%">
                          50%
                        </MaxButton>
                        <MaxButton
                          onClick={() =>
                            onUserInput(Field.LIQUIDITY_PERCENT, "75")
                          }
                          width="20%">
                          75%
                        </MaxButton>
                        <MaxButton
                          onClick={() =>
                            onUserInput(Field.LIQUIDITY_PERCENT, removePercentage.toString())
                          }
                          width="20%">
                          Max
                        </MaxButton>
                      </RowBetween>
                    </>
                  )}
                </AutoColumn>
              </LightCard>
              {!showDetailed && (
                <>
                  <ColumnCenter>
                    <ArrowDown size="16" color={theme.deprecated_text2} />
                  </ColumnCenter>
                  <LightCard>
                    <AutoColumn gap="10px">
                      <RowBetween>
                        <Text fontSize={24} fontWeight={500}>
                          {formattedAmounts[Field.CURRENCY_A] || "-"}
                        </Text>
                        <RowFixed>
                          <CurrencyLogo
                            currency={currencyA}
                            style={{ marginRight: "12px" }}
                          />
                          <Text
                            fontSize={24}
                            fontWeight={500}
                            id="remove-liquidity-tokena-symbol">
                            {currencyA?.symbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      <RowBetween>
                        <Text fontSize={24} fontWeight={500}>
                          {formattedAmounts[Field.CURRENCY_B] || "-"}
                        </Text>
                        <RowFixed>
                          <CurrencyLogo
                            currency={currencyB}
                            style={{ marginRight: "12px" }}
                          />
                          <Text
                            fontSize={24}
                            fontWeight={500}
                            id="remove-liquidity-tokenb-symbol">
                            {currencyB?.symbol}
                          </Text>
                        </RowFixed>
                      </RowBetween>
                      {chainId && (oneCurrencyIsWETH || oneCurrencyIsETH) ? (
                        <RowBetween style={{ justifyContent: "flex-end" }}>
                          {oneCurrencyIsETH ? (
                            <StyledInternalLink
                              to={`/remove/v1/${currencyA?.isNative &&
                                chainId &&
                                WRAPPED_NATIVE_CURRENCY[chainId]
                                ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
                                : currencyIdA
                                }/${currencyB?.isNative &&
                                  chainId &&
                                  WRAPPED_NATIVE_CURRENCY[chainId]
                                  ? WRAPPED_NATIVE_CURRENCY[chainId]?.address
                                  : currencyIdB
                                }`}>
                              Receive WETH
                            </StyledInternalLink>
                          ) : oneCurrencyIsWETH ? (
                            <StyledInternalLink
                              to={`/remove/v1/${currencyA &&
                                WRAPPED_NATIVE_CURRENCY[chainId]?.equals(
                                  currencyA
                                )
                                ? "ETH"
                                : currencyIdA
                                }/${currencyB &&
                                  WRAPPED_NATIVE_CURRENCY[chainId]?.equals(
                                    currencyB
                                  )
                                  ? "ETH"
                                  : currencyIdB
                                }`}>
                              Receive ETH
                            </StyledInternalLink>
                          ) : null}
                        </RowBetween>
                      ) : null}
                    </AutoColumn>
                  </LightCard>
                </>
              )}

              {showDetailed && (
                <>
                  <CurrencyInputPanel
                    value={formattedAmounts[Field.LIQUIDITY]}
                    onUserInput={onLiquidityInput}
                    onMax={() => {
                      onUserInput(Field.LIQUIDITY_PERCENT, removePercentage.toString());
                    }}
                    showMaxButton={!atMaxAmount}
                    currency={pair?.liquidityToken}
                    pair={pair}
                    id="liquidity-amount"
                  />
                  <ColumnCenter>
                    <ArrowDown size="16" color={theme.deprecated_text2} />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance={true}
                    value={formattedAmounts[Field.CURRENCY_A]}
                    onUserInput={onCurrencyAInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, removePercentage.toString())}
                    showMaxButton={!atMaxAmount}
                    currency={currencyA}
                    label={"Output"}
                    onCurrencySelect={handleSelectCurrencyA}
                    id="remove-liquidity-tokena"
                  />
                  <ColumnCenter>
                    <Plus size="16" color={theme.deprecated_text2} />
                  </ColumnCenter>
                  <CurrencyInputPanel
                    hideBalance={true}
                    value={formattedAmounts[Field.CURRENCY_B]}
                    onUserInput={onCurrencyBInput}
                    onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, removePercentage.toString())}
                    showMaxButton={!atMaxAmount}
                    currency={currencyB}
                    label={"Output"}
                    onCurrencySelect={handleSelectCurrencyB}
                    id="remove-liquidity-tokenb"
                  />
                </>
              )}
              {pair && (
                <div style={{ padding: "10px 20px" }}>
                  <RowBetween>
                    <Trans>Price:</Trans>
                    <div>
                      1 {currencyA?.symbol} ={" "}
                      {tokenA ? pair.priceOf(tokenA).toSignificant(6) : "-"}{" "}
                      {currencyB?.symbol}
                    </div>
                  </RowBetween>
                  <RowBetween>
                    <div />
                    <div>
                      1 {currencyB?.symbol} ={" "}
                      {tokenB ? pair.priceOf(tokenB).toSignificant(6) : "-"}{" "}
                      {currencyA?.symbol}
                    </div>
                  </RowBetween>
                </div>
              )}
              <div style={{ position: "relative" }}>
                {!account ? (
                  <TraceEvent
                    events={[Event.onClick]}
                    name={EventName.CONNECT_WALLET_BUTTON_CLICKED}
                    properties={{ received_swap_quote: false }}
                    element={ElementName.CONNECT_WALLET_BUTTON}>
                    <ButtonLight onClick={toggleWalletModal}>
                      <Trans>Connect Wallet</Trans>
                    </ButtonLight>
                  </TraceEvent>
                ) : (
                  <div>
                    {showAuth ?
                      isValid && parsedAmounts[Field.CURRENCY_A] && parsedAmounts[Field.CURRENCY_B] && !is2FAVerified ? (
                        <div>
                          <div className="verify-token">
                            <div className="inbutContainer">
                              <div className="custom-search twofa">
                                <input
                                  onChange={(e) => { e.target.value = e.target.value.slice(0, 6); setToken(e.target.value) }}
                                  type="number"
                                  className="px-5 py-3 rounded border-0"
                                  placeholder="Enter 2FA"
                                />
                              </div>
                            </div>
                            <button onClick={verify} className="ed-btn">
                              Verify
                            </button>
                          </div>
                          <p className="tokenValidation">{errors}</p>
                        </div>
                      ) : null
                      : null}
                    {is2FAVerified ? (<RowBetween>
                      <ButtonConfirmed
                        onClick={onAttemptToApprove}
                        confirmed={
                          approval === ApprovalState.APPROVED ||
                          signatureData !== null
                        }
                        disabled={
                          approval !== ApprovalState.NOT_APPROVED ||
                          signatureData !== null
                        }
                        mr="0.5rem"
                        fontWeight={500}
                        fontSize={16}>
                        {approval === ApprovalState.PENDING ? (
                          <Dots>
                            <Trans>Approving</Trans>
                          </Dots>
                        ) : approval === ApprovalState.APPROVED ||
                          signatureData !== null ? (
                          <Trans>Approved</Trans>
                        ) : (
                          <Trans>Approve</Trans>
                        )}
                      </ButtonConfirmed>
                      <ButtonError
                        onClick={() => {
                          setShowConfirm(true);
                        }}
                        disabled={
                          !isValid ||
                          (signatureData === null &&
                            approval !== ApprovalState.APPROVED)
                        }
                        error={
                          !isValid &&
                          !!parsedAmounts[Field.CURRENCY_A] &&
                          !!parsedAmounts[Field.CURRENCY_B]
                        }>
                        <Text fontSize={16} fontWeight={500}>
                          {error || <Trans>Remove</Trans>}
                        </Text>
                      </ButtonError>
                    </RowBetween>) : null}

                  </div>
                )}
              </div>
            </AutoColumn>
          </Wrapper>
        </AppBody>
      </div>
    </>
  );
}
