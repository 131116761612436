import { useEffect, useRef } from 'react';

import {
  widget,
  ChartingLibraryWidgetOptions,
  ResolutionString,
} from '../../charting_library_cloned_data/charting_library/';
import * as React from 'react';
import datafeed from '../../trading-view/datafeed';

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions['symbol'];
  interval: ChartingLibraryWidgetOptions['interval'];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions['library_path'];
  chartsStorageUrl: ChartingLibraryWidgetOptions['charts_storage_url'];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions['charts_storage_api_version'];
  clientId: ChartingLibraryWidgetOptions['client_id'];
  userId: ChartingLibraryWidgetOptions['user_id'];
  fullscreen: ChartingLibraryWidgetOptions['fullscreen'];
  autosize: ChartingLibraryWidgetOptions['autosize'];
  studiesOverrides: ChartingLibraryWidgetOptions['studies_overrides'];
  container: ChartingLibraryWidgetOptions['container'];
  theme: ChartingLibraryWidgetOptions['theme'];
  numeric_formatting: ChartingLibraryWidgetOptions['numeric_formatting'];
  timezone: ChartingLibraryWidgetOptions['timezone'];
}


export const TVChartContainer = ({symbol, theme}) => {
  
  const chartContainerRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;

  const defaultProps: Omit<ChartContainerProps, 'container'> = {
    // symbol: `Kraken:DAI/USDT`,
    symbol: `Swap:${symbol}`,
    interval: '1D' as ResolutionString,
    datafeedUrl: 'https://demo_feed.tradingview.com',
    libraryPath: '../../charting_library_cloned_data/charting_library/',
    chartsStorageUrl: 'https://saveload.tradingview.com',
    chartsStorageApiVersion: '1.1',
    clientId: 'tradingview.com',
    userId: 'public_user_id',
    fullscreen: false,
    autosize: true,
    studiesOverrides: {},
    theme,
    numeric_formatting: { decimal_sign: "." },
    timezone: 'Asia/Dubai',
  };

  useEffect(() => {
    const widgetOptions: ChartingLibraryWidgetOptions = {
      symbol: defaultProps.symbol as string,
      // BEWARE: no trailing slash is expected in feed URL
     
      datafeed,
      interval: defaultProps.interval as ChartingLibraryWidgetOptions['interval'],
      container: chartContainerRef.current,
      library_path: defaultProps.libraryPath as string,
      // debug: true,

      locale: 'en',
      disabled_features: ['use_localstorage_for_settings'],
      enabled_features: ['study_templates'],
      charts_storage_url: defaultProps.chartsStorageUrl,
      charts_storage_api_version: defaultProps.chartsStorageApiVersion,
      client_id: defaultProps.clientId,
      user_id: defaultProps.userId,
      fullscreen: defaultProps.fullscreen,
      autosize: defaultProps.autosize,
      studies_overrides: defaultProps.studiesOverrides,
      theme: defaultProps.theme,
      timezone: defaultProps.timezone
    };

    const script = document.createElement("script");
    script.type = "text/jsx";
    script.src = "%PUBLIC_URL%/charting_library_cloned_data/charting_library/charting_library.js";
    document.head.appendChild(script);

    const tvWidget = new widget(widgetOptions);

    // tvWidget.onChartReady(() => {
    //   tvWidget.headerReady().then(() => {
    //     const button = tvWidget.createButton();
    //     button.setAttribute('title', 'Click to show a notification popup');
    //     button.classList.add('apply-common-tooltip');
    //     button.addEventListener('click', () => tvWidget.showNoticeDialog({
    //       title: 'Notification',
    //       body: 'TradingView Charting Library API works correctly',
    //       callback: () => {
    //         console.log('Noticed!');
    //       },
    //     }));
    //     button.innerHTML = 'Check API';
    //   });
    // });

    tvWidget.onChartReady(() => {
      console.log("The TradingView chart widget is fully loaded and ready!");
    });

    return () => {
      script.remove()
      // tvWidget.remove();
    };
  },[symbol,theme]);

  return (
    <div
      id="tv_chart_container"
      ref={chartContainerRef}
      className={'TVChartContainer h-100'}
    />
  );
};