// import { JsonRpcProvider } from "@ethersproject/providers";

import { SupportedChainId } from "./chains";

const INFURA_KEY = process.env.REACT_APP_INFURA_KEY;
if (typeof INFURA_KEY === "undefined") {
  throw new Error(
    `REACT_APP_INFURA_KEY must be a defined environment variable`
  );
}

// export const MAINNET_PROVIDER = new JsonRpcProvider(
//   `https://mainnet.infura.io/v3/${INFURA_KEY}`
//   // `https://summer-fragrant-fire.matic.quiknode.pro/3715ea0bf6159ac7ae3323877ebadd102134b958//`
// );

/**
 * These are the network URLs used by the interface when there is not another available source of chain data
 */
export const RPC_URLS: { [key in SupportedChainId]: string } = {
  // [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.CELO]: `https://forno.celo.org`,
  // [SupportedChainId.CELO_ALFAJORES]: `https://alfajores-forno.celo-testnet.org`,
  // [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.GOERLI]: `https://goerli.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISM]: `https://optimism-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.OPTIMISTIC_GOERLI]: `https://optimism-goerli.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_ONE]: `https://arbitrum-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.ARBITRUM_GOERLI]: `https://arbitrum-goerli.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON]: `https://polygon-mainnet.infura.io/v3/${INFURA_KEY}`,
  // [SupportedChainId.POLYGON_MUMBAI]: `https://polygon-mumbai.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.MAINNET]: `https://wiser-indulgent-pallet.quiknode.pro/96808de5e014cf49d0869a259069125e3adab111/`,
  [SupportedChainId.GOERLI]: `https://late-snowy-spree.ethereum-goerli.quiknode.pro/d8b05401840f0b403d9e85d8bf284dabccaedfca/`,
  [SupportedChainId.OPTIMISM]: `https://still-restless-brook.optimism.quiknode.pro/852cfd239fdf09a123151455f5a0b1713b9af12b/`,
  [SupportedChainId.OPTIMISTIC_GOERLI]: `https://smart-compatible-forest.optimism-goerli.quiknode.pro/c89b2d67b31ff3ec352eed2e9f53f4699398ab41/`,
  [SupportedChainId.ARBITRUM_ONE]: `https://falling-spring-spree.arbitrum-mainnet.quiknode.pro/a905ec64e4a919463ed20b8dbcc98fe4320c2194/`,
  [SupportedChainId.ARBITRUM_GOERLI]: `https://proud-burned-putty.arbitrum-goerli.quiknode.pro/60b4312f53ac19345ed411144a4ad96d9002fe86/`,
  [SupportedChainId.POLYGON]: `https://summer-fragrant-fire.matic.quiknode.pro/3715ea0bf6159ac7ae3323877ebadd102134b958/`,
  [SupportedChainId.POLYGON_MUMBAI]: `https://light-warmhearted-shape.matic-testnet.discover.quiknode.pro/2c2c5a4057e54f6bf01753d67128b6d8a50556c5/`,
  [SupportedChainId.RINKEBY]: `https://rinkeby.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.KOVAN]: `https://kovan.infura.io/v3/${INFURA_KEY}`,
  [SupportedChainId.CELO]: `https://forno.celo.org`,
  [SupportedChainId.CELO_ALFAJORES]: `https://alfajores-forno.celo-testnet.org`,
};

export type RpcUrl = { [key in SupportedChainId]: string }
