// import panaromaswapPOLYGON from './tokenLists/panaromaswapPOLYGON.json';

const BA_LIST =
  "https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json";
 
export const POLYGON_LIST = "https://raw.githubusercontent.com/panaromafinance/tokens-list/main/panaromaswap-polygon.json";
export const MAINNET_LIST = "https://raw.githubusercontent.com/panaromafinance/tokens-list/main/panaromaswap-mainnet.json";
export const ARBITRUM_LIST = "https://raw.githubusercontent.com/panaromafinance/tokens-list/main/panaromaswap-arbitrum.json";
export const OPTIMISM_LIST = "https://raw.githubusercontent.com/panaromafinance/tokens-list/main/panaromaswap-optimism.json";

export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST];

 
// this is the default list of lists that are exposed to users
// lower index == higher priority for token import
const DEFAULT_LIST_OF_LISTS_TO_DISPLAY: string[] = [
  ARBITRUM_LIST,
  OPTIMISM_LIST,
  POLYGON_LIST,
  MAINNET_LIST
];

export const DEFAULT_LIST_OF_LISTS: string[] = [
  ...DEFAULT_LIST_OF_LISTS_TO_DISPLAY,
  ...UNSUPPORTED_LIST_URLS // need to load dynamic unsupported tokens as well
];

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [POLYGON_LIST, MAINNET_LIST, OPTIMISM_LIST, ARBITRUM_LIST];
