import { Trans } from "@lingui/macro";
import { useWeb3React } from "@web3-react/core";
import useTheme from "hooks/useTheme";
import JSBI from "jsbi";
import styled from "styled-components/macro";

import { OutlineCard } from "../../components/Card";
import { AutoColumn } from "../../components/Column";
import PoolCard from "../../components/earn/PoolCard";
import {
  CardBGImage,
  CardNoise,
  CardSection,
  DataCard
} from "../../components/earn/styled";
import Loader from "../../components/Loader";
import { RowBetween } from "../../components/Row";
import { BIG_INT_ZERO } from "../../constants/misc";
import { STAKING_REWARDS_INFO, useStakingInfo } from "../../state/stake/hooks";
import { ExternalLink, ThemedText } from "../../theme";
import { Countdown } from "./Countdown";

const PageWrapper = styled(AutoColumn)`
  max-width: 640px;
  width: 100%;
`;

const TopSection = styled(AutoColumn)`
  max-width: 720px;
  width: 100%;
`;

const PoolSection = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 10px;
  row-gap: 15px;
  width: 100%;
  justify-self: center;
`;

const DataRow = styled(RowBetween)`
  ${({ theme }) => theme.mediaWidth.upToSmall`
flex-direction: column;
`};
`;

export default function Earn() {
  const theme = useTheme();
  const { chainId } = useWeb3React();

  // staking info for connected account
  const stakingInfos = useStakingInfo();

  /**
   * only show staking cards with balance
   * @todo only account for this if rewards are inactive
   */
  const stakingInfosWithBalance = stakingInfos?.filter((s) =>
    JSBI.greaterThan(s.stakedAmount.quotient, BIG_INT_ZERO)
  );

  // toggle copy if rewards are inactive
  const stakingRewardsExist = Boolean(
    typeof chainId === "number" &&
      (STAKING_REWARDS_INFO[chainId]?.length ?? 0) > 0
  );

  return (
    <PageWrapper gap="lg" justify="center">
      <TopSection gap="md">
        <DataCard>
          <CardBGImage />
          <CardNoise />
          <CardSection>
            <AutoColumn gap="md">
              <RowBetween>
                <ThemedText.DeprecatedWhite fontWeight={600}>
                  <Trans>Panaromaswap liquidity mining</Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>
              <RowBetween>
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>
                    Deposit your Liquidity Provider tokens to receive PANA, the
                    Panaromaswap protocol governance token.
                  </Trans>
                </ThemedText.DeprecatedWhite>
              </RowBetween>{" "}
              <ExternalLink
                style={{
                  color: theme.deprecated_white,
                  textDecoration: "underline"
                }}
                href="https://panaromaswap.org/blog/PANA/"
                target="_blank">
                <ThemedText.DeprecatedWhite fontSize={14}>
                  <Trans>Read more about PANA</Trans>
                </ThemedText.DeprecatedWhite>
              </ExternalLink>
            </AutoColumn>
          </CardSection>
          <CardBGImage />
          <CardNoise />
        </DataCard>
      </TopSection>

      <AutoColumn gap="lg" style={{ width: "100%", maxWidth: "720px" }}>
        <DataRow style={{ alignItems: "baseline" }}>
          <ThemedText.DeprecatedMediumHeader style={{ marginTop: "0.5rem" }}>
            <Trans>Participating pools</Trans>
          </ThemedText.DeprecatedMediumHeader>
          <Countdown exactEnd={stakingInfos?.[0]?.periodFinish} />
        </DataRow>

        <PoolSection>
          {stakingRewardsExist && stakingInfos?.length === 0 ? (
            <Loader style={{ margin: "auto" }} />
          ) : !stakingRewardsExist ? (
            <OutlineCard>
              <Trans>No active pools</Trans>
            </OutlineCard>
          ) : stakingInfos?.length !== 0 &&
            stakingInfosWithBalance.length === 0 ? (
            <OutlineCard>
              <Trans>No active pools</Trans>
            </OutlineCard>
          ) : (
            stakingInfosWithBalance?.map((stakingInfo) => {
              // need to sort by added liquidity here
              return (
                <PoolCard
                  key={stakingInfo.stakingRewardAddress}
                  stakingInfo={stakingInfo}
                />
              );
            })
          )}
        </PoolSection>
      </AutoColumn>
    </PageWrapper>
  );
}
